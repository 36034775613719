import React, { useState, useEffect } from "react";
import { Empty, Switch, Select, Popover } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getAxiosHeaders, getQueryParams } from "../../../utils/Utils";
import { Badge, 
  DropdownToggle, 
  DropdownMenu, 
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";

export const CustomersTable = ({ history, businessID, currency, ...props }) => {
  const [tableData, setTableData] = useState({meta: {total_records: 0}, customers:[]});
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [purchaseDateRange, setPurchaseDateRange] = useState([null, null]);
  const [purchaseDateFrom, purchaseDateTo] = purchaseDateRange;
  const [dobDateRange, setDOBDateRange] = useState([null, null]);
  const [dobDateFrom, dobDateTo] = dobDateRange;
  const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    customer_search: '',
    phone_search: '',
    email_search: '',
    purchase_range: null,
    purchaseRangeStart: null,
    purchaseRangeEnd: null,
    dob_range: null,
    dobRangeStart: null,
    dobRangeEnd: null,
    category: 'all',
  });
  const [categories, setCategories] = useState([
    {value: "all", label: "All"},
    {value: "top_customers", label: "Top Customers"},
    {value: "returning_customers", label: "Returning Customers"},
    {value: "onetime_customers", label: "Onetime Customers"},
    {value: "customers_owing", label: "Customers Owing"},
  ])
  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  const [hideBirthdayAlert, setHideBirthdayAlert] = useState(false);
  const [birthdayCustomers, setBirthdayCustomers] = useState([]);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onRangeChange = (dates, type) => {
    const [start, end] = dates;
    let params = filters;

    if(type === 'purchases') {
      if(!moment(start).isValid() || !moment(end).isValid()){
        params.purchase_range = null;
        params.purchaseRangeStart = null;
        params.purchaseRangeEnd = null;
        setFilters({...params});
        return;
      }
  
      params.purchase_range = `${moment(start).format("YYYY-MM-DD")},${moment(end).format("YYYY-MM-DD")}`;
      params.purchaseRangeStart = start;
      params.purchaseRangeEnd = end;
      setFilters({...params});
      
    } else {
      if(!moment(start).isValid() || !moment(end).isValid()){
        params.dob_range = null;
        params.dobRangeStart = null;
        params.dobRangeEnd = null;
        setFilters({...params});
        return;
      }
  
      params.dob_range = `${moment(start).format("MMMM")},${moment(end).format("MMMM")}`;
      params.dobRangeStart = moment(start).format("MMMM");
      params.dobRangeEnd = moment(end).format("MMMM");
      
      setFilters({...params});
    }
    
  };

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const selectAll = () => {
    setCheckAll(prevCheckAll => {
      if (prevCheckAll) {
        setCheckedBoxes([]);
      } else {
        const allIDs = tableData.customers.map(item => item.customer_id);
        setCheckedBoxes(allIDs);
      }
      return !prevCheckAll;
    });
  }

  const handleCheckboxChange = (value) => {
    setCheckedBoxes(prevBoxes => {
      if (prevBoxes.includes(value)) {
          return prevBoxes.filter(id => id !== value);
      } else {
          return [...prevBoxes, value];
      }
    });
    if (checkAll) {
        setCheckAll(false);
    }
  };

  const handleSingleDelete = (orderID) => {
    setCheckedBoxes([orderID]);

    setTimeout(() => {
      toggleModal('deleteAlertModal');          
    }, 500);
  }

  const currentUrl = (filters) => {
    let customer_search = filters.customer_search.length > 0 ? `&cs=${filters.customer_search}` : ``;
    let phone_search = filters.phone_search.length > 0 ? `&ps=${filters.phone_search}` : ``;
    let email_search = filters.email_search.length > 0 ? `&es=${filters.email_search}` : ``;
    let category = filters.category.length > 0 ? `&ct=${filters.category}` : ``;
    let purchase_date_range = filters.purchase_range ? `&pd=${filters.purchase_range}` : ``;
    let dob_date_range = filters.dob_range ? `&dob=${filters.dob_range}` : ``;
    
    if( (customer_search !== '' ||
    phone_search !== '' ||
    email_search !== '' ||
    purchase_date_range !== '' ||
    dob_date_range !== '' ||
    filters.category !== 'all') && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${customer_search}${phone_search}${email_search}${category}${purchase_date_range}${dob_date_range}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const handleBirthdayClick = () => {
    setHideBirthdayAlert(true);
    const updatedFilters = {
      ...filters,
      dob_range: `${moment().format("MMMM")},${moment().format("MMMM")}`,
      dobRangeStart: moment().format("MMMM"),
      dobRangeEnd: moment().format("MMMM"),
    };
    setFilters(updatedFilters);
    getCustomers(updatedFilters);
  };

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getCustomers(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      customer_search: '',
      phone_search: '',
      email_search: '',
      purchase_range: null,
      purchaseRangeStart: null,
      purchaseRangeEnd: null,
      dob_range: null,
      dobRangeStart: null,
      dobRangeEnd: null,
      category: 'all',
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getCustomers(params);
  }

  const filterCustomers = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getCustomers(filters);
  }

  const getCustomers = (filters) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + "/customers", {
      params: {
        business_id: businessID,
        page: filters.page,
        customer: filters.customer_search,
        phone_number: filters.phone_search,
        email: filters.email_search,
        category: filters.category,
        purchase_date_from: !filters.purchaseRangeStart ? null : moment(filters.purchaseRangeStart).format("YYYY-MM-DD"),
        purchase_date_to: !filters.purchaseRangeEnd ? null : moment(filters.purchaseRangeEnd).format("YYYY-MM-DD"),
        dob_date_from: !filters.dobRangeStart ? null : filters.dobRangeStart,
        dob_date_to: !filters.dobRangeEnd ? null : filters.dobRangeEnd,
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({meta: {total_records: 0}, customers:[]});
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({meta: {total_records: 0}, customers:[]});

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getBirthdayCustomers = () => {
    axios.get(baseURLs.API_URL + `/customers/birthdays/${moment().format("MMMM")}`, {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let customers = responseInfo.data.customers;

        setBirthdayCustomers([...customers]);
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getCustomersCategory = () => {

    axios.get(baseURLs.API_URL + "/customers/category", {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let category = responseInfo.data.category;
        const newCategories = category.map(c => ({ value: c, label: c}));
    
        // Combine existing categories with new ones
        setCategories(prevCategories => [...prevCategories, ...newCategories]);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteCustomersByIDs = () => {
    toggleModal('deleteLoadingModal');

    let _checkedBoxes = checkedBoxes;
    if(_checkedBoxes.length > 0){
      _checkedBoxes = _checkedBoxes.map((id) => id);
    }

    axios.delete(baseURLs.API_URL + "/customers/delete/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        customers: JSON.stringify(_checkedBoxes),
      }      
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_customer_ids;
      let failedIDs = responseInfo.data.failed_customer_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getCustomers(filters);
        toggleModal('successDeleteModal');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getCustomers(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `customers` : `customer`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `customers` : `customer`} failed to get deleted`);
        toggleModal('deleteErrorModal');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `customers` : `customer`}. The selected ${failedIDs.length > 1 ? `customers were` : `customer was`} not found.`);
        toggleModal('deleteErrorModal');
      }

      setCheckedBoxes([]);
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteCustomersByFilter = () => {
    toggleModal('deleteLoadingModal');

    axios.delete(baseURLs.API_URL + "/customers/delete/by-filters", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        customer: filters.customer_search,
        phone_number: filters.phone_search,
        email: filters.sales_id_search,
        category: filters.category,
        purchase_date_from: !filters.purchaseDateFrom ? null : moment(filters.purchaseDateFrom).format("YYYY-MM-DD"),
        purchase_date_to: !filters.purchaseDateTo ? null : moment(filters.purchaseDateTo).format("YYYY-MM-DD"),
        dob_date_from: !filters.dobDateFrom ? null : moment(filters.dobDateFrom).format("MMMM"),
        dob_date_to: !filters.dobDateTo ? null : moment(filters.dobDateTo).format("MMMM"),
      }
    })
    .then((response) => {
      getCustomers(filters);
      toggleModal('successDeleteModal');
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleSelectedRecordsAction = (action) => {
    if(!checkAll && checkedBoxes.length === 0){
      toggleModal('noSelectionAlertModal');
      return;
    }

    toggleModal('deleteAlertModal');    
  }

  const downloadCustomers = () => {
    setErrorMessage("");
    toggleModal('loadingModal');
    
    axios.get(baseURLs.API_URL + "/customers/download", {
      params: {
        business_id: businessID,
        customer: filters.customer_search,
        phone_number: filters.phone_search,
        email: filters.email_search,
        category: filters.category,
        purchase_date_from: !filters.purchaseRangeStart ? null : moment(filters.purchaseRangeStart).format("YYYY-MM-DD"),
        purchase_date_to: !filters.purchaseRangeEnd ? null : moment(filters.purchaseRangeEnd).format("YYYY-MM-DD"),
        dob_date_from: !filters.dobRangeStart ? null : filters.dobRangeStart,
        dob_date_to: !filters.dobRangeEnd ? null : filters.dobRangeEnd,
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('No customer found');        
        toggleModal('alertModal');
      } else {
        let responseInfo = response.data;
        setDownloadLink(responseInfo.data.download_link);
        toggleModal('downloadModal');
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    onRangeChange(purchaseDateRange, "purchases");
  }, [purchaseDateRange])

  useEffect(() => {
    onRangeChange(dobDateRange, "dob");
  }, [dobDateRange])

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let customer_search = queryParams.hasOwnProperty('cs') ? queryParams.cs : '';
    let phone_search = queryParams.hasOwnProperty('ps') ? queryParams.ps : '';
    let email_search = queryParams.hasOwnProperty('es') ? queryParams.es : '';
    let purchase_date_range = queryParams.hasOwnProperty('pd') ? queryParams.pd : null;
    let dob_date_range = queryParams.hasOwnProperty('dob') ? queryParams.dob : null;
    let category = queryParams.hasOwnProperty('ct') ? queryParams.ct : 'all';
    let purchaseRangeStart = null;
    let purchaseRangeEnd = null;
    let dobRangeStart = null;
    let dobRangeEnd = null;

    if(purchase_date_range){
      let rangeSplit = purchase_date_range.split(',');
      purchaseRangeStart = new Date(rangeSplit[0]);
      purchaseRangeEnd = new Date(rangeSplit[1]);
      setPurchaseDateRange([purchaseRangeStart, purchaseRangeEnd]);
    }

    if(dob_date_range){
      let rangeSplit = dob_date_range.split(',');
      dobRangeStart = new Date(rangeSplit[0]);
      dobRangeEnd = new Date(rangeSplit[1]);
      setDOBDateRange([dobRangeStart, dobRangeEnd]);
    }

    let params = filters;
    params.page = page;
    params.customer_search = customer_search;
    params.phone_search = phone_search;
    params.email_search = email_search;
    params.category = category;
    params.purchase_range = purchase_date_range;
    params.purchaseRangeStart = purchaseRangeStart;
    params.purchaseRangeEnd = purchaseRangeEnd;
    params.dob_range = dob_date_range;
    params.dobRangeStart = dobRangeStart;
    params.dobRangeEnd = dobRangeEnd;

    setFilters(params);
    getCustomersCategory();
    getBirthdayCustomers();
    getCustomers(params);
  }, []);

  
  return (
    <Block>
      {
        birthdayCustomers.length > 0 && !hideBirthdayAlert && 
        <div className="card-bordered card mb-2">
          <div className="card-inner py-lg-1">
            <div className="nk-help">
              <div className="nk-help-img m-0">
                <lord-icon
                  src="https://cdn.lordicon.com/liqouopv.json"
                  trigger="loop"
                  delay="1000"
                  colors="primary:#eeca66,secondary:#3080e8"
                  style={{width:"120px",height:"120px"}}>
                </lord-icon>
              </div>
              <div className="nk-help-text">
                <h5>{birthdayCustomers.length} Birthday {birthdayCustomers.length > 1 ? `Celebrations` : `Celebration`} This Month</h5>
                <p className="text-soft">{birthdayCustomers.length} of your valued {birthdayCustomers.length > 1 ? `customers are celebrating their birthdays` : `customer is celebrating their birthday`} this month.</p>
              </div>
              <div className="nk-help-action">
              <button className="btn btn-outline-primary" onClick={handleBirthdayClick}>
                View Customers
              </button>
              </div>
            </div>
          </div>
        </div>
      }
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Customers` : `Customer`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Customers Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="customer_search">
                                    Customer Name / Customer ID
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="customer_search" className="form-control form-control-lg" id="customer_search" defaultValue={filters.customer_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="phone_search">
                                    Phone Number
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="phone_search" className="form-control form-control-lg" id="phone_search" defaultValue={filters.phone_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="phone_search">
                                    Email
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="email_search" className="form-control form-control-lg" id="email_search" defaultValue={filters.email_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="category">
                                    Category
                                  </label>
                                  <div className="form-control-wrap">
                                    <Select size="large"                          
                                      placeholder="Select category"
                                      defaultValue={filters.category}
                                      style={{ width: "100%" }} 
                                      name="category"
                                      onChange={(value) => onSelectChange(value, "category")}
                                      filterOption={(input, option) =>
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      options={categories} 
                                      showSearch /> 
                                  </div>
                                </div>
                                {
                                  ['all','top_customers'].includes(filters.category) &&
                                  <div className="form-group">
                                    <label className="form-label">Purchase Date Range</label>                          
                                    <div className="form-control-wrap">
                                      <DatePicker
                                        selectsRange={true}
                                        startDate={purchaseDateFrom}
                                        endDate={purchaseDateTo}
                                        onChange={(date) => {
                                          setPurchaseDateRange(date);
                                        }}
                                        dateFormat="dd/MM/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        isClearable
                                        // monthsShown={2}
                                        className="form-control form-control-lg date-picker"
                                      />
                                    </div>
                                    <div className="form-note fs-10px">
                                      Date Format: <code>dd/mm/yyyy</code>
                                    </div>
                                  </div>
                                }
                                <div className="form-group">
                                  <label className="form-label">Date of Birth</label>                          
                                  <div className="form-control-wrap">
                                    <DatePicker
                                      selectsRange={true}
                                      startDate={dobDateFrom}
                                      endDate={dobDateTo}
                                      onChange={(date) => {
                                        setDOBDateRange(date);
                                      }}
                                      dateFormat="MMMM"
                                      showMonthYearPicker
                                      isClearable
                                      // monthsShown={2}
                                      className="form-control form-control-lg date-picker"
                                    />
                                  </div>
                                  <div className="form-note fs-10px">
                                  </div>
                                </div>
                                <div className="form-group">
                                  <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterCustomers();} } size="md">
                                    Apply Filter
                                  </Button>

                                  {
                                    hasFilters &&
                                    <Button className="ms-3 text-muted" color="lighter" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="md">
                                      Reset Filter
                                    </Button>
                                  }
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-light justify-content-start">
                              <span className="sub-text">Use the form above to filter your customers.</span>
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getCustomers(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={downloadCustomers} title="Download">
                          <Icon name="download-cloud"></Icon>
                        </Button>
                        { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={"Preparing Customers"} descriptionText={"Please wait while your customers list is being prepared for download."} /> }
                        
                        { 
                          activeModal === "downloadModal" &&
                          <SuccessModal showModal={true} toggleModal={() => toggleModal('downloadModal')}
                            headerText={"Customers Ready"} descriptionText={"Customers CSV is ready for download."} 
                            leftButtonText={"Download"} leftButtonOnClick={() => {window.open(downloadLink, '_blank').focus(); toggleModal('downloadModal');}}
                          />
                        }
                        
                        {
                          activeModal === "alertModal" &&
                          <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
                            headerText={"Download Failed"} descriptionText={errorMessage}                       
                          />
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="p-0">
                {
                  tableData.customers.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No customers found</h5><span>Add a customer using the button below.</span>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/customers/b/${businessID}/add`}>
                        <button className="btn btn-outline-primary">Add a Customer</button>
                      </Link>                    
                    </div>
                  </div>
                  :
                  <>      
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input
                              type="checkbox"
                              checked={checkAll}
                              onChange={selectAll}
                              className="custom-control-input table-checkbox"
                              id="select_all" />
                            <label className="custom-control-label" htmlFor="select_all"/>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text">Name</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Purchases</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Phone</span>
                        </DataTableRow>
                        <DataTableRow size="xl">
                          <span className="sub-text">Email</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Balance Due</span>
                        </DataTableRow>
                        <DataTableRow size="xl">
                          <span className="sub-text">Last Purchase</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt text-capitalize" style={{letterSpacing: "normal"}}>
                                <li>
                                  <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSelectedRecordsAction('cancel')}>
                                    <Icon name="trash"></Icon>
                                    <span>Delete Selected Customers</span>
                                    {
                                      activeModal === "deleteAlertModal" &&
                                      <AlertModal showModal={true} toggleModal={() => toggleModal('deleteAlertModal')}
                                        headerText={checkedBoxes.length > 1 ? `Delete Customers` : `Delete Customer`} 
                                        descriptionText={`${checkAll ? 
                                        `Are you sure you want to delete all ${tableData.meta.total_records} customers. This action can not be reverted.` 
                                        :
                                        `Are you sure you want to delete ${checkedBoxes.length > 1 ? `these ${checkedBoxes.length} selected customers` : `this selected customer`}. This action can not be reverted.`}`}
                                        leftButtonText={`Delete`}
                                        leftButtonOnClick={checkAll ? deleteCustomersByFilter : deleteCustomersByIDs} />
                                    }
                                  </DropdownItem>
                                </li>
                              </ul>
                              {
                                activeModal === "noSelectionAlertModal" &&
                                <AlertModal showModal={true} toggleModal={() => toggleModal('noSelectionAlertModal')}
                                  headerText={`Error`} 
                                  descriptionText={`No customer has been selected. Select a customer and try again.`}
                                />
                              }

                              { activeModal === "deleteLoadingModal" && <LoadingModal showModal={true} headerText={`Deleting...`} descriptionText={`Please wait while the ${checkedBoxes.length > 1 ? `customers are` : `customer is`} being deleted`} /> }
                        
                              { 
                                activeModal === "successDeleteModal" &&
                                <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
                                  headerText={`Deleted`} descriptionText={`Customer(s) deleted successfully.`} 
                                />
                              }
                              
                              {
                                activeModal === "deleteErrorModal" &&
                                <AlertModal showModal={true} toggleModal={() => toggleModal(null)}
                                  headerText={"Error"} descriptionText={errorMessage}                       
                                />
                              }
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.customers.map((data, index) => {
                          let balanceStatusDot = data.balance_due !== '0.00' ? <span className="dot dot-danger me-1"></span> : '';
                          let customerName = data.customer_name ? data.customer_name.trim() : `Customer`;
                          return (
                            <DataTableItem key={index}>
                              <DataTableRow className="nk-tb-col-check">
                                <div className="custom-control custom-control-sm custom-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={checkAll || checkedBoxes.includes(data.customer_id)}
                                    onChange={() => handleCheckboxChange(data.customer_id)}
                                    className="custom-control-input table-checkbox"
                                    id={`item${data.customer_id}`} 
                                  />
                                  <label className="custom-control-label" htmlFor={`item${data.customer_id}`} />
                                </div>
                              </DataTableRow>
                              <DataTableRow>
                                <Link to={`${process.env.PUBLIC_URL}/customers/b/${businessID}/profile/${data.customer_id}`}>
                                  <div className="user-card">
                                    <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                                    {
                                      data.last_feedback.message !== null ?
                                      <Popover content={data.customer_feedback} title="Feedback Message" trigger="click" placement="bottomLeft">
                                        <span className="text-gray me-2">
                                          <em className={`icon ni ${data.last_feedback.rate === null && `ni-happy text-light`} ${data.last_feedback.rate === "bad" && `ni-sad text-danger`} ${data.last_feedback.rate === "okay" && `ni-meh text-dark`} ${data.last_feedback.rate === "excellent" && `ni-happy text-success` }`} style={{fontSize: "40px", lineHeight: "1.5"}}></em>
                                        </span>
                                      </Popover>
                                      :
                                      <span className="text-gray me-2">
                                        <em className={`icon ni ${data.last_feedback.rate === null && `ni-happy text-light`} ${data.last_feedback.rate === "bad" && `ni-sad text-danger`} ${data.last_feedback.rate === "okay" && `ni-meh text-dark`} ${data.last_feedback.rate === "excellent" && `ni-happy text-success` }`} style={{fontSize: "40px", lineHeight: "1.5"}}></em>
                                      </span>
                                    }
                                    <div className="user-info">
                                      <span className={`tb-lead ${!data.customer_name && `fw-normal`}`}>
                                        {customerName}{" "}
                                        { 
                                          moment(data.date_of_birth).format("M") === moment().format("M")  &&
                                          <small className="text-muted" title="Birthday"><em className="icon ni ni-gift text-orange"></em></small>
                                        }
                                      </span>

                                      {
                                        filters.dob_range && data.date_of_birth &&
                                        <div title="Birthday"><small className="text-muted"><em className="icon ni ni-gift text-orange"></em> {moment(data.date_of_birth).format("Do MMMM")}</small></div>
                                      }

                                      <div><small>(ID: {data.customer_code})</small></div>
                                      <span className="d-md-none">{data.customer_phone_number}</span>
                                      
                                      {
                                        data.balance_due !== '0.00' &&
                                        <div className={`price d-md-none ${data.balance_due !== '0.00' && `text-danger`}`}><small>Balance Due: </small> {balanceStatusDot} <small> {currency} </small> {data.balance_due} </div>
                                      }
                                    </div>
                                  </div>
                                </Link>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount">
                                  <b>{data.total_purchases_count}</b><small> / {currency} </small> {data.total_purchases_amount}
                                </span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{data.customer_phone_number}</span>
                              </DataTableRow>
                              <DataTableRow size="xl">
                                <span>{data.customer_email}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className={`${data.balance_due !== '0.00' && `text-danger`}`}>{balanceStatusDot} <small> {currency} </small> {data.balance_due}</span>
                              </DataTableRow>
                              <DataTableRow size="xl">
                                <span>{data.last_purchase_date && moment(data.last_purchase_date).format("Do MMM YYYY")}</span>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}?cs=${data.customer_code}`}>                                            
                                          <Icon name="coins"></Icon>
                                          <span>View Purchases</span>
                                        </Link>
                                      </li>
                                      <li className="divider"></li>
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/customers/b/${businessID}/profile/${data.customer_id}`}>                                            
                                          <Icon name="eye"></Icon>
                                          <span>View Profile</span>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/customers/b/${businessID}/edit/${data.customer_id}`}>
                                          <Icon name="edit-alt"></Icon>
                                          <span>Edit</span>
                                        </Link>
                                      </li>
                                      <li className="divider"></li>
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/message-customers/b/${businessID}?compose=message&c_id=${data.customer_id}&cn=${customerName}`}>
                                          <Icon name="msg"></Icon>
                                          <span>Send Message</span>
                                        </Link>
                                      </li>
                                      <li>
                                        <a href={`tel:${data.customer_phone_number}`}>
                                          <Icon name="call"></Icon>
                                          <span>Call</span>
                                        </a>
                                      </li>
                                      <li className="divider"></li>
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSingleDelete(data.customer_id)}>
                                          <Icon name="trash"></Icon>
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                  </>
                }              
              </div>
            </div>
          </Card>
        </>
      }
    </Block>
  );
};
