import React, { useState, useEffect } from "react";
import { Empty } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getAxiosHeaders } from "../../../utils/Utils";
import { 
  Badge, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter 
} from "reactstrap";
import {
  Block,
  Icon,
  Button
} from "../../../components/Component";
import { PaginationWithOnclick } from "./Pagination";
import { TableLoader } from "../../../utils/Loaders";

export const CustomerPurchasedItemsTable = ({ history, businessID, customerID, currency, ...props }) => {
  const [tableData, setTableData] = useState({meta: {total_records: 0}, items:[]});
  const [loading, setLoading] = useState(true);
  const [hasFilters, setHasFilters] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [dateRange, setDateRange] = useState([null, null]);
  const [dateFrom, dateTo] = dateRange;
  const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    item_name: '',
    range: null,
    rangeStart: null,
    rangeEnd: null,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onRangeChange = (dates) => {
    const [start, end] = dates;
    let params = filters;

    if(!moment(start).isValid() || !moment(end).isValid()){
      params.range = null;
      params.rangeStart = null;
      params.rangeEnd = null;
      setFilters({...params});
      return;
    }

    params.range = `${moment(start).format("YYYY-MM-DD")},${moment(end).format("YYYY-MM-DD")}`;
    params.rangeStart = start;
    params.rangeEnd = end;
    setFilters({...params});
  };

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getPurchasedItems(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      item_name: '',
      range: null,
      rangeStart: null,
      rangeEnd: null
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getPurchasedItems(params);
  }

  const filterPurchasedItems = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getPurchasedItems(filters);
  }
 
  const getPurchasedItems = (_filters) => {
    setLoading(true);

    axios.get(baseURLs.API_URL + `/customers/purchased-items/${customerID}`, {
      params: {
        business_id: businessID,
        item_name: _filters.item_name,
        date_from: !moment(_filters.rangeStart).isValid() ? null : moment(filters.rangeStart).format("YYYY-MM-DD"),
        date_to: !moment(_filters.rangeEnd).isValid() ? null : moment(filters.rangeEnd).format("YYYY-MM-DD"),
        page: _filters.page,
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({meta: {total_records: 0}, items:[]});
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({meta: {total_records: 0}, items:[]});

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    onRangeChange(dateRange);
  }, [dateRange])

  useEffect(() => {
    setLoading(true);
    getPurchasedItems(filters);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
        {
          tableData.items.length === 0 ?
          <div className="nk-block">
            <div className="text-center m-5">
              <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
              <div className="price-plan-info">
                <span className="title fw-normal">No items found</span>
              </div>
              <div className="price-plan-action">
                <Link to={`${process.env.PUBLIC_URL}/create-invoice-receipt/b/${businessID}`}>
                  <button className="btn btn-outline-primary btn-sm">Create Invoice / Receipt</button>
                </Link>                    
              </div>
            </div>
          </div>
          :
          <div className="nk-block">
            <div className="card-title-group mb-3">
              <h6 className="lead-text m-0">
                {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Items` : `Item`}`}
              </h6>
              <div className="card-tools me-n1">
                <ul className="btn-toolbar">
                  <li>
                    <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                      { hasFilters && <div className="dot dot-primary"></div> }
                      <Icon name="search"></Icon>
                    </Button>
                    {
                      activeModal === "filterModal" &&
                      <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                        <ModalHeader
                          toggle={() => toggleModal('filterModal')}
                          close={
                            <button className="close" onClick={() => toggleModal('filterModal')}>
                              <Icon name="cross" />
                            </button>
                          }
                        >
                          Purchased Item Filter
                        </ModalHeader>
                        <ModalBody>
                          <form>
                            <div className="form-group">
                              <label className="form-label" htmlFor="item_name">
                                Item Name
                              </label>
                              <div className="form-control-wrap">
                                <input type="text" onChange={onInputChange} name="item_name" className="form-control form-control-lg" id="item_name" defaultValue={filters.item_name} />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="form-label">Date Range</label>                          
                              <div className="form-control-wrap">
                                <DatePicker
                                  selectsRange={true}
                                  startDate={dateFrom}
                                  endDate={dateTo}
                                  onChange={(date) => {
                                    setDateRange(date);
                                  }}
                                  dateFormat="dd/MM/yyyy"
                                  showMonthDropdown
                                  showYearDropdown
                                  isClearable
                                  // monthsShown={2}
                                  className="form-control form-control-lg date-picker"
                                />
                              </div>
                              <div className="form-note fs-10px">
                                Date Format: <code>dd/mm/yyyy</code>
                              </div>
                            </div>
                            <div className="form-group">
                              <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterPurchasedItems();} } size="md">
                                Apply Filter
                              </Button>

                              {
                                hasFilters &&
                                <Button className="ms-3 text-muted" color="lighter" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="md">
                                  Reset Filter
                                </Button>
                              }
                            </div>
                          </form>
                        </ModalBody>
                        <ModalFooter className="bg-light justify-content-start">
                          <span className="sub-text">Use the form above to filter purchased items.</span>
                        </ModalFooter>
                      </Modal>
                    }
                  </li>
                </ul>
              </div>
            </div>
            <div className="nk-tb-list nk-tb-ulist is-compact border round-sm">
              <div className="nk-tb-item nk-tb-head">
                <div className="nk-tb-col">
                  <span className="sub-text">Product/Service</span>
                </div>
                <div className="nk-tb-col tb-col-sm">
                  <span className="sub-text">No. Times</span>
                </div>
                <div className="nk-tb-col tb-col-sm">
                  <span className="sub-text">Quantity</span>
                </div>
                <div className="nk-tb-col tb-col-sm">
                  <span className="sub-text">Total</span>
                </div>
              </div>
              
                {
                  tableData.items.map((data, index) => {
                    

                    return (
                      <div className="nk-tb-item" key={index}>
                        <div className="nk-tb-col">
                          <span>
                            <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                            {data.item_name}
                            <span className="d-sm-none ms-1 text-muted"><br/>
                              {`No. Times: ${data.purchased_times}  •  Qty: ${data.purchased_quantity}  •  Total: `} 
                              <small className="text-muted">{currency} </small>{data.purchased_amount}
                            </span>
                          </span>
                        </div>
                        <div className="nk-tb-col tb-col-sm">
                          <span className="amount">{data.purchased_times}</span>
                        </div>
                        <div className="nk-tb-col tb-col-sm">
                          <span className="amount">
                            {data.purchased_quantity}
                          </span>
                        </div>
                        <div className="nk-tb-col tb-col-sm">
                          <span className="amount">
                            <small className="text-muted">{currency} </small>
                            {data.purchased_amount}
                          </span>
                        </div>
                      </div>
                    )
                  })
                }
                
            </div>
            <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />
          </div>
        }
        </>
      }
    </Block>
  );
};
