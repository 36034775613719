import React, { useState, useEffect } from "react";
import { Empty, Switch, Select, Popover } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import imagePlaceholder from "../../../images/product_placeholder.png";
import { getAxiosHeaders, getQueryParams, findUpper } from "../../../utils/Utils";
import { Badge, 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  UserAvatar,
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";

export const ProductsPerformanceTable = ({ history, businessID, currency, ...props }) => {
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0
    }, 
    products: []
  });
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [dateFrom, dateTo] = dateRange;
   const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    product_name: '',
    SKU: '',
    barcode: '',
    date_range: null,
    dateRangeStart: null,
    dateRangeEnd: null,
  });
  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const onRangeChange = (date) => {
    const [start, end] = date;
    let params = filters;

    if(!moment(start).isValid() || !moment(end).isValid()){
      params.date_range = null;
      params.dateRangeStart = null;
      params.dateRangeEnd = null;
      setFilters({...params});
      return;
    }

    params.date_range = `${moment(start).format("YYYY-MM-DD")},${moment(end).format("YYYY-MM-DD")}`;
    params.dateRangeStart = start;
    params.dateRangeEnd = end;
    setFilters({...params});
    
  };

  const currentUrl = (filters) => {
    let product_name = filters.product_name.length > 0 ? `&ps=${filters.product_name}` : ``;
    let SKU_search = filters.SKU.length > 0 ? `&sku=${filters.SKU}` : ``;
    let barcode_search = filters.barcode.length > 0 ? `&bc=${filters.barcode}` : ``;
    let date_range = filters.date_range !== null ? `&d=${filters.date_range}` : ``;
    
    if( (product_name !== '' ||
    SKU_search !== '' ||
    barcode_search !== '' ||
    date_range !== '') && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${product_name}${SKU_search}${barcode_search}${date_range}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getProducts(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      product_name: '',
      SKU: '',
      barcode: '',
      date_range: null,
      dateRangeStart: null,
      dateRangeEnd: null,
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getProducts(params);
  }

  const filterProducts = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getProducts(filters);
  }

  const getProducts = (filters) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + "/products/analytics", {
      params: {
        business_id: businessID,
        page: filters.page,
        product_name: filters.product_name,
        SKU: filters.SKU,
        barcode: filters.barcode,
        date_from: !filters.dateRangeStart ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !filters.dateRangeEnd ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
          }, 
          products:[]
        });
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
          }, 
          products:[]
        });

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const downloadProducts = () => {
    setErrorMessage("");
    toggleModal('loadingModal');
    
    axios.get(baseURLs.API_URL + "/products/analytics/download", {
      params: {
        business_id: businessID,
        product_name: filters.product_name,
        SKU: filters.SKU,
        barcode: filters.barcode,
        date_from: !filters.dateRangeStart ? null : moment(filters.dateRangeStart).format("YYYY-MM-DD"),
        date_to: !filters.dateRangeEnd ? null : moment(filters.dateRangeEnd).format("YYYY-MM-DD"),
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('No product found');        
        toggleModal('alertModal');
      } else {
        let responseInfo = response.data;
        setDownloadLink(responseInfo.data.download_link);
        toggleModal('downloadModal');
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    onRangeChange(dateRange);
  }, [dateRange])

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let product_name = queryParams.hasOwnProperty('ps') ? queryParams.ps : '';
    let SKU = queryParams.hasOwnProperty('sku') ? queryParams.sku : '';
    let barcode = queryParams.hasOwnProperty('bc') ? queryParams.bc : '';
    let date_range = queryParams.hasOwnProperty('d') ? queryParams.d : null;
    let dateRangeStart = null;
    let dateRangeEnd = null;

    if(date_range){
      let rangeSplit = date_range.split(',');
      dateRangeStart = new Date(rangeSplit[0]);
      dateRangeEnd = new Date(rangeSplit[1]);
      setDateRange([dateRangeStart, dateRangeEnd]);
    }

    let params = filters;
    params.page = page;
    params.product_name = product_name;
    params.SKU = SKU;
    params.barcode = barcode;
    params.date_range = date_range;
    params.dateRangeStart = dateRangeStart;
    params.dateRangeEnd = dateRangeEnd;

    setFilters(params);
    getProducts(params);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <Card className="card-bordered card-stretch">
          <div className="card-inner-group">
            <div className="card-inner">
              <div className="card-title-group">
                <div className="card-title">
                  <h6 className="title">
                    {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Products` : `Product`}`}
                  </h6>
                </div>
                <div className="card-tools me-n1">
                  <ul className="btn-toolbar">
                    <li>
                      <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                        { hasFilters && <div className="dot dot-primary"></div> }
                        <Icon name="search"></Icon>
                      </Button>
                      {
                        activeModal === "filterModal" &&
                        <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                          <ModalHeader
                            toggle={() => toggleModal('filterModal')}
                            close={
                              <button className="close" onClick={() => toggleModal('filterModal')}>
                                <Icon name="cross" />
                              </button>
                            }
                          >
                            Products Filter
                          </ModalHeader>
                          <ModalBody>
                            <form>
                              <div className="form-group">
                                <label className="form-label" htmlFor="product_name">
                                  Product Name
                                </label>
                                <div className="form-control-wrap">
                                  <input type="text" onChange={onInputChange} name="product_name" className="form-control form-control-lg" id="product_name" defaultValue={filters.product_name} />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="form-label" htmlFor="SKU">
                                  SKU
                                </label>
                                <div className="form-control-wrap">
                                  <input type="text" onChange={onInputChange} name="SKU" className="form-control form-control-lg" id="SKU" defaultValue={filters.SKU} />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="form-label" htmlFor="barcode">
                                  Barcode
                                </label>
                                <div className="form-control-wrap">
                                  <input type="text" onChange={onInputChange} name="barcode" className="form-control form-control-lg" id="barcode" defaultValue={filters.barcode} />
                                </div>
                              </div>
                              <div className="form-group">
                                <label className="form-label">Date Range</label>                          
                                <div className="form-control-wrap">
                                  <DatePicker
                                    selectsRange={true}
                                    startDate={dateFrom}
                                    endDate={dateTo}
                                    onChange={(date) => {
                                      setDateRange(date);
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    showMonthDropdown
                                    showYearDropdown
                                    isClearable
                                    // monthsShown={2}
                                    className="form-control form-control-lg date-picker"
                                  />
                                </div>
                                <div className="form-note fs-10px">
                                  Date Format: <code>dd/mm/yyyy</code>
                                </div>
                              </div>
                              <div className="form-group mt-5">
                                <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterProducts();} } size="md">
                                  Apply Filter
                                </Button>

                                {
                                  hasFilters &&
                                  <Button className="ms-3 text-muted" color="lighter" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="md">
                                    Reset Filter
                                  </Button>
                                }
                              </div>
                            </form>
                          </ModalBody>
                          <ModalFooter className="bg-light justify-content-start">
                            <span className="sub-text">Use the form above to filter your products.</span>
                          </ModalFooter>
                        </Modal>
                      }
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getProducts(filters)}}>
                        <Icon name="redo"></Icon>
                      </Button>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <Button className="btn-icon btn-trigger" onClick={downloadProducts} title="Download">
                        <Icon name="download-cloud"></Icon>
                      </Button>
                      { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={"Preparing Products"} descriptionText={"Please wait while your products list is being prepared for download."} /> }
                      
                      { 
                        activeModal === "downloadModal" &&
                        <SuccessModal showModal={true} toggleModal={() => toggleModal('downloadModal')}
                          headerText={"Products Ready"} descriptionText={"Products CSV is ready for download."} 
                          leftButtonText={"Download"} leftButtonOnClick={() => {window.open(downloadLink, '_blank').focus(); toggleModal('downloadModal');}}
                        />
                      }
                      
                      {
                        activeModal === "alertModal" &&
                        <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
                          headerText={"Download Failed"} descriptionText={errorMessage}                       
                        />
                      }
                    </li>
                  </ul>
                </div>
              </div>
            </div>          
            <div className="p-0">
              {
                tableData.products.length === 0 ?
                <div className="text-center m-5">
                  <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                  <div className="price-plan-info">
                    <h5 className="title fw-normal">No products found</h5><span>Add a product using the button below.</span>
                  </div>
                  <div className="price-plan-action">
                    <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}/add`}>
                      <button className="btn btn-outline-primary">Add a Product</button>
                    </Link>                    
                  </div>
                </div>
                :
                <>      
                  <DataTableBody>
                    <DataTableHead className="bg-lighter fw-bold">
                      <DataTableRow>
                        <span className="sub-text">Product</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span className="sub-text">Times Sold</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span className="sub-text">Quantity</span>
                      </DataTableRow>
                      <DataTableRow size="md">
                        <span className="sub-text">Total</span>
                      </DataTableRow>
                    </DataTableHead>
                    {
                      tableData.products.map((data, index) => {
                        
                        return (
                          <DataTableItem key={index}>
                            <DataTableRow>
                              <div className="user-card">
                                <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                                <div className="user-info">
                                  <span className={`tb-lead fw-normal`}>
                                    {data.product_name}{" "}
                                  </span>

                                  {data.barcode && <div className="price d-none d-md-block"><small className="text-muted">Barcode: {data.barcode}</small></div>}
                                  {data.SKU && <div className="price d-none d-md-block"><small className="text-muted">SKU: {data.SKU}</small></div>}
                                  <div className="price d-md-none">Times Sold: {data.times_sold} • Qty: {data.quantity} • Total: <small>{currency} </small> {data.total_amount}</div>
                                </div>
                              </div>
                            </DataTableRow>
                            <DataTableRow size="md">
                              <span>{data.times_sold}</span>
                            </DataTableRow>
                            <DataTableRow size="md">
                              <span>{data.quantity}</span>
                            </DataTableRow>
                            <DataTableRow size="md">
                              <span className="tb-amount">
                                <small>{currency} </small> {data.total_amount}
                              </span>
                            </DataTableRow>
                          </DataTableItem>
                        )
                      })
                    }
                  </DataTableBody>   
                  <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                </>
              }              
            </div>
          </div>
        </Card>
      }
    </Block>
  );
};
