import React, { useState, useEffect } from "react";
import { Empty, Switch, Select, Popover } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import moment from 'moment';
import { getAxiosHeaders, getQueryParams, findUpper } from "../../../utils/Utils";
import { Badge, 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  UserAvatar,
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";

export const RawMaterialsTable = ({ history, businessID, currency, ...props }) => {
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0,
      total_quantity: 0,
      total_materials_in_stock: 0,
      total_materials_out_of_stock: 0,
      cost_price_total_amount: '0.00'
    }, 
    materials: []
  });
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
   const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    material_name: '',
    status: 'all',
  });
  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const selectAll = () => {
    setCheckAll(prevCheckAll => {
      if (prevCheckAll) {
        setCheckedBoxes([]);
      } else {
        const allIDs = tableData.materials.map(item => item.material_id);
        setCheckedBoxes(allIDs);
      }
      return !prevCheckAll;
    });
  }

  const handleCheckboxChange = (value) => {
    setCheckedBoxes(prevBoxes => {
      if (prevBoxes.includes(value)) {
          return prevBoxes.filter(id => id !== value);
      } else {
          return [...prevBoxes, value];
      }
    });
    if (checkAll) {
        setCheckAll(false);
    }
  };

  const currentUrl = (filters) => {
    let material_name = filters.material_name.length > 0 ? `&ms=${filters.material_name}` : ``;
    let status_search = filters.status.length > 0 ? `&st=${filters.status}` : ``;

    if((material_name !== '' || filters.status !== 'all')  && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${material_name}${status_search}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getRawMaterials(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      material_name: '',
      status: 'all'
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getRawMaterials(params);
  }

  const filterMaterials = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getRawMaterials(filters);
  }

  const getRawMaterials = (filters) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + "/raw-materials", {
      params: {
        business_id: businessID,
        page: filters.page,
        material_name: filters.material_name,
        status: filters.status
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
            total_quantity: 0,
            total_materials_in_stock: 0,
            total_materials_out_of_stock: 0,
            cost_price_total_amount: '0.00'
          }, 
          materials:[]
        });
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
            total_quantity: 0,
            total_materials_in_stock: 0,
            total_materials_out_of_stock: 0,
            cost_price_total_amount: '0.00'
          }, 
          materials:[]
        });

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteRawMaterialsByIDs = () => {
    toggleModal('deleteLoadingModal');

    let _checkedBoxes = checkedBoxes;
    if(_checkedBoxes.length > 0){
      _checkedBoxes = _checkedBoxes.map((id) => parseInt(id));
    }

    axios.delete(baseURLs.API_URL + "/raw-materials/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        materials: JSON.stringify(_checkedBoxes),
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_ids;
      let failedIDs = responseInfo.data.failed_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getRawMaterials(filters);
        toggleModal('successDeleteModal');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getRawMaterials(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `materials` : `material`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `materials` : `material`} failed to get deleted`);
        toggleModal('deleteErrorModal');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `materials` : `material`}. The selected ${failedIDs.length > 1 ? `materials were` : `material was`} not found.`);
        toggleModal('deleteErrorModal');
      }

      setCheckedBoxes([]);
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteRawMaterialsByFilter = () => {
    toggleModal('deleteLoadingModal');

    axios.delete(baseURLs.API_URL + "/raw-materials/by-filters", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        material_name: filters.material_name,
        status: filters.status
      }
    })
    .then((response) => {
      getRawMaterials(filters);
      toggleModal('successDeleteModal');
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleSelectedRecordsAction = (action) => {
    if(!checkAll && checkedBoxes.length === 0){
      toggleModal('noSelectionAlertModal');
      return;
    }

    toggleModal('deleteAlertModal');    
  }

  const downloadRawMaterials = () => {
    setErrorMessage("");
    toggleModal('loadingModal');
    
    axios.get(baseURLs.API_URL + "/raw-materials/download", {
      params: {
        business_id: businessID,
        material_name: filters.material_name,
        status: filters.status
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('No material found');        
        toggleModal('alertModal');
      } else {
        let responseInfo = response.data;
        setDownloadLink(responseInfo.data.download_link);
        toggleModal('downloadModal');
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }


  const handleSingleDelete = (materialID) => {
    setCheckedBoxes([materialID]);

    setTimeout(() => {
      toggleModal('deleteAlertModal');       
    }, 500);
  }

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let material_name = queryParams.hasOwnProperty('ms') ? queryParams.ms : '';
    let status_search = queryParams.hasOwnProperty('st') ? queryParams.st : 'all';

    let params = filters;
    params.page = page;
    params.material_name = material_name;
    params.status = status_search;

    setFilters(params);
    getRawMaterials(params);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          {
            tableData.materials.length > 0 &&
            <div className="row g-gs mb-5">
              <div className="col-md-12">
                <Card className="card-bordered">
                  <div className="card-inner">
                    <div className="analytic-data-group analytic-ov-group g-3 justify-content-md-evenly ">
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-success">Materials In-Stock</div>
                        <div className="amount">{tableData.meta.total_materials_in_stock}</div>
                      </div>
                      <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-danger">Materials Out-of-Stock</div>
                        <div className="amount">{tableData.meta.total_materials_out_of_stock}</div>
                      </div>
                      <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-dark">Total Stock Amount</div>
                        <div className="amount">
                          <small className="text-muted fw-light me-1">{currency}</small> 
                          {tableData.meta.cost_price_total_amount}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>   
              </div>
            </div>
          }
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Materials` : `Material`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Raw Material Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="material_name">
                                    Material Name
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="material_name" className="form-control form-control-lg" id="material_name" defaultValue={filters.material_name} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="phone_search">
                                    Status
                                  </label>
                                  <div className="form-control-wrap">
                                    <Select size="large"                          
                                      placeholder="Select status"
                                      defaultValue={filters.status}
                                      style={{ width: "100%" }} 
                                      name="status"
                                      onChange={(value) => onSelectChange(value, "status")}
                                      filterOption={(input, option) =>
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      options={[
                                        {value: "all", label: "All"},
                                        {value: "in_stock", label: "In-Stock"},
                                        {value: "low_stock", label: "Low In-Stock"},
                                        {value: "out_of_stock", label: "Out-of-Stock"},
                                      ]} 
                                      showSearch /> 
                                  </div>
                                </div>
                                <div className="form-group mt-5">
                                  <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterMaterials();} } size="md">
                                    Apply Filter
                                  </Button>

                                  {
                                    hasFilters &&
                                    <Button className="ms-3 text-muted" color="lighter" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="md">
                                      Reset Filter
                                    </Button>
                                  }
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-light justify-content-start">
                              <span className="sub-text">Use the form above to filter your raw materials.</span>
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getRawMaterials(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={downloadRawMaterials} title="Download">
                          <Icon name="download-cloud"></Icon>
                        </Button>
                        { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={"Preparing Materials"} descriptionText={"Please wait while your raw materials list is being prepared for download."} /> }
                        
                        { 
                          activeModal === "downloadModal" &&
                          <SuccessModal showModal={true} toggleModal={() => toggleModal('downloadModal')}
                            headerText={"Materials Ready"} descriptionText={"Raw materials CSV is ready for download."} 
                            leftButtonText={"Download"} leftButtonOnClick={() => {window.open(downloadLink, '_blank').focus(); toggleModal('downloadModal');}}
                          />
                        }
                        
                        {
                          activeModal === "alertModal" &&
                          <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
                            headerText={"Download Failed"} descriptionText={errorMessage}                       
                          />
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="p-0">
                {
                  tableData.materials.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No raw material found</h5><span>Add a raw material using the button below.</span>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/raw-materials/b/${businessID}/add`}>
                        <button className="btn btn-outline-primary">Add a Raw Material</button>
                      </Link>                    
                    </div>
                  </div>
                  :
                  <>      
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input
                              type="checkbox"
                              checked={checkAll}
                              onChange={selectAll}
                              className="custom-control-input table-checkbox"
                              id="select_all" />
                            <label className="custom-control-label" htmlFor="select_all"/>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text">Material</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Unit Price</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Quantity</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Status</span>
                        </DataTableRow>
                        <DataTableRow size="xl">
                          <span className="sub-text">Last Updated</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt text-capitalize" style={{letterSpacing: "normal"}}>
                                <li>
                                  <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSelectedRecordsAction('cancel')}>
                                    <Icon name="trash"></Icon>
                                    <span>Delete Selected Materials</span>
                                    {
                                      activeModal === "deleteAlertModal" &&
                                      <AlertModal showModal={true} toggleModal={() => toggleModal('deleteAlertModal')}
                                        headerText={checkedBoxes.length > 1 ? `Delete Materials` : `Delete Material`} 
                                        descriptionText={`${checkAll ? 
                                        `Are you sure you want to delete all ${tableData.meta.total_records} materials. This action can not be reverted.` 
                                        :
                                        `Are you sure you want to delete ${checkedBoxes.length > 1 ? `these ${checkedBoxes.length} selected materials` : `this selected material`}. This action can not be reverted.`}`}
                                        leftButtonText={`Delete`}
                                        leftButtonOnClick={checkAll ? deleteRawMaterialsByFilter : deleteRawMaterialsByIDs} />
                                    }
                                  </DropdownItem>
                                </li>
                              </ul>
                              {
                                activeModal === "noSelectionAlertModal" &&
                                <AlertModal showModal={true} toggleModal={() => toggleModal('noSelectionAlertModal')}
                                  headerText={`Error`} 
                                  descriptionText={`No material have been selected. Select a material and try again.`}
                                />
                              }

                              { activeModal === "deleteLoadingModal" && <LoadingModal showModal={true} headerText={`Deleting...`} descriptionText={`Please wait while the ${checkedBoxes.length > 1 ? `materials are` : `material is`} being deleted`} /> }
                        
                              { 
                                activeModal === "successDeleteModal" &&
                                <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
                                  headerText={`Deleted`} descriptionText={`Material(s) deleted successfully.`} 
                                />
                              }
                              
                              {
                                activeModal === "deleteErrorModal" &&
                                <AlertModal showModal={true} toggleModal={() => toggleModal(null)}
                                  headerText={"Error"} descriptionText={errorMessage}                       
                                />
                              }
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.materials.map((data, index) => {
                          let materialStatus = <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="success">In-Stock</Badge></div>;                            
                          let materialStatusDot = <span className="dot dot-success ms-1"></span>;

                          switch (data.stock_status) {
                            case 'in_stock':
                              materialStatus = data.quantity > data.restock_level ? <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="success">In-Stock</Badge></div> : <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="warning">Low-in-Stock</Badge></div>;
                              materialStatusDot = data.quantity > data.restock_level ? <span className="dot dot-success ms-1"></span> : <span className="dot dot-warning ml-1"></span>;
                              break;
                            
                            case 'out_of_stock':
                              materialStatus = <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="danger">Out-of-Stock</Badge></div>;
                              materialStatusDot = <span className="dot dot-danger ms-1"></span>;
                              break;
                          }
                          return (
                            <DataTableItem key={index}>
                              <DataTableRow className="nk-tb-col-check">
                                <div className="custom-control custom-control-sm custom-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={checkAll || checkedBoxes.includes(data.material_id)}
                                    onChange={() => handleCheckboxChange(data.material_id)}
                                    className="custom-control-input table-checkbox"
                                    id={`item${data.material_id}`} 
                                  />
                                  <label className="custom-control-label" htmlFor={`item${data.material_id}`} />
                                </div>
                              </DataTableRow>
                              <DataTableRow>
                                <div className="user-card">
                                  <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                                  <div className="user-info">
                                    <span className={`tb-lead fw-normal`}>
                                      {data.material_name}{" "}
                                      <span className="d-md-none">{materialStatusDot}</span>
                                    </span>
                                    <div className="price d-md-none"><small>{currency} </small> {data.unit_cost} • Qty: {data.quantity}<small className="ps-1">{data.unit}</small></div>
                                  </div>
                                </div>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount">
                                  <small>{currency} </small> {data.unit_cost}
                                </span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{data.quantity}<small className="ps-1">{data.unit}</small></span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                {materialStatus}
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{data.updated_at && moment(data.updated_at).format("Do MMM YYYY")}</span>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/raw-materials/b/${businessID}/edit/${data.material_id}`}>
                                          <Icon name="edit-alt"></Icon>
                                          <span>Edit</span>
                                        </Link>
                                      </li>
                                      <li className="divider"></li>
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSingleDelete(data.material_id)}>
                                          <Icon name="trash"></Icon>
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                  </>
                }              
              </div>
            </div>
          </Card>
        </>
      }
    </Block>
  );
};
