import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import classnames from "classnames";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem, 
  Spinner, 
  Alert,
  Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  BackTo,
} from "../components/Component";
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders, phoneNumberFormatter } from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { SalesTable } from "./components/tables/SalesTable";
import { SuccessModal } from "./components/AlertModals";
import { Input, Select, InputNumber, Divider, Popover} from 'antd';
import { CustomerSalesTable } from "./components/tables/CustomerSalesTable";
import { CustomerPurchasedItemsTable } from "./components/tables/CustomerPurchasedItemsTable";
import { CustomerNotes } from "./components/tables/CustomerNotes";
import { CustomerCreditLogs } from "./components/tables/CustomerCreditLogs";

const CustomerProfile = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [loading, setLoading] = useState(true);
  const [businessID, setBusinessID] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [customerInfo, setCustomerInfo] = useState({});
  const [activeTab, setActiveTab] = useState("items");
  const [activeModal, setActiveModal] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };


  const getCustomerInfo = (businessID, customerID) => {

    axios.get(baseURLs.API_URL + `/customers/${customerID}`, {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let customerInfo = responseInfo.data.customer_info;
        
        // update customer info data 
        setCustomerInfo(customerInfo)
        setLoading(false);
      }
    }).catch((error) => {
      console.log(error);
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    const _businessID = parseInt(match.params.businessID, 10);
    const _customerID = match.params.customerID;
    if ( !Number.isInteger(_businessID) && _customerID ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', _businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == _businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(_businessID);
    setCustomerID(_customerID);
    getCustomerInfo(_businessID, _customerID);
  }, [match.params.businessID, match.params.customerID]);


  return (<React.Fragment>
    <Head title="Customer Details" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Customer Details
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/customers/b/${businessID}/edit/${customerID}`}>
                        <Button color="primary">
                          <Icon name="edit-alt"></Icon>
                          <span>Edit</span>
                        </Button>
                      </Link>
                    </li>
                    <li>
                      <BackTo className="mb-3" history={history} link={`/customers/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block className="wide-sm m-auto">
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
        </Block>

        {/* success modal */}
        { 
          activeModal === "successModal" &&
          <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
            headerText={`Updated`} descriptionText={`The customer's information been updated successfully.`} 
          />
        }

        <Block>
          <div className="row g-gs">
            <div className="col-xl-4">
              <div className="card card-bordered">
                <div className="card-inner-group">
                  <div className="card-inner">
                    <div className="user-card user-card-s2">
                      <span className="text-muted">
                        {
                          customerInfo.last_feedback.message !== null ?
                          <Popover content={customerInfo.customer_feedback} title="Feedback Message" trigger="click" placement="bottomLeft">
                            <span className="text-gray me-2">
                              <em className={`icon ni ${customerInfo.last_feedback.rate === null && `ni-happy text-light`} ${customerInfo.last_feedback.rate === "bad" && `ni-sad text-danger`} ${customerInfo.last_feedback.rate === "okay" && `ni-meh text-dark`} ${customerInfo.last_feedback.rate === "excellent" && `ni-happy text-success` }`} style={{fontSize: "70px"}}></em>
                            </span>
                          </Popover>
                          :
                          <span className="text-gray me-2">
                            <em className={`icon ni ${customerInfo.last_feedback.rate === null && `ni-happy text-light`} ${customerInfo.last_feedback.rate === "bad" && `ni-sad text-danger`} ${customerInfo.last_feedback.rate === "okay" && `ni-meh text-dark`} ${customerInfo.last_feedback.rate === "excellent" && `ni-happy text-success` }`} style={{fontSize: "70px"}}></em>
                          </span>
                        }
                      </span>
                      <div className="user-info">
                        <h5>{customerInfo.customer_name}</h5>
                        <span className="sub-text">{phoneNumberFormatter(customerInfo.customer_phone_number)}</span>
                        {customerInfo.customer_email && <span className="sub-text">{customerInfo.customer_email}</span>}
                      </div>
                    </div>
                  </div>
                  
                  <div className="card-inner">
                    <div className="row text-center" style={{whiteSpace: "nowrap"}}>
                      <div className="col-6">
                        <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}?p=1&ps=${customerInfo.customer_phone_number}`}>
                          <div className="profile-stats pointer-cursor">
                            <span className="amount">{customerInfo.total_purchases_count}</span>
                            <span className="sub-text">Purchases</span>
                          </div>
                        </Link>
                      </div>
                      <div className="col-6">
                        <Link to={`${process.env.PUBLIC_URL}/customer-feedback/b/${businessID}?p=1&ps=${customerInfo.customer_phone_number}`}>
                          <div className="profile-stats pointer-cursor">
                            <span className="amount">{customerInfo.total_feedback}</span>
                            <span className="sub-text">Feedbacks</span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner card-inner-sm text-center d-block d-lg-none">
                    <button className={`btn ${showMoreDetails ? `btn-light` : `btn-primary`} btn-block`} onClick={() => {setShowMoreDetails(!showMoreDetails)}}>{`${showMoreDetails ? `Hide`:`View`}`} Details</button>
                  </div>

                  <div className={`card-inner  ${showMoreDetails ? `d-block` : `d-none d-lg-block`} `}>
                    <h6 className="overline-title mb-2">Details</h6>
                    <div className="row g-3">
                      <div className="col-sm-6 col-xl-12">
                        <span className="sub-text">Customer Code:</span>
                        <span>{customerInfo.customer_code}</span>
                      </div>
                      <div className="col-sm-6 col-xl-12">
                        <span className="sub-text">Birthday:</span>
                        <span>{moment(customerInfo.date_of_birth).format("Do MMM")}</span>
                      </div>
                      <div className="col-sm-6 col-xl-12">
                        <span className="sub-text">Location:</span>
                        <span>{customerInfo.location_info.location}</span>
                      </div>
                      <div className="col-sm-6 col-xl-12">
                        <span className="sub-text">Discovery Source:</span>
                        <span>{customerInfo.discovery_source}</span>
                      </div>
                      <div className="col-sm-6 col-xl-12">
                        <span className="sub-text">Category:</span>
                        <span>
                          {
                            customerInfo.category.length > 0 ?
                            <ul className="preview-list">
                              {
                                customerInfo.category.map((cat, index) => {
                                  return (
                                    <li className="preview-item" key={index}>
                                      <span className="badge bg-outline-primary rounded-pill">{cat}</span>
                                    </li>
                                  );
                                })
                              }
                            </ul>
                            :
                            <span className="text-muted"></span>
                          }
                        </span>
                      </div>
                      <div className="col-sm-6 col-xl-12">
                        <span className="sub-text">Created At:</span>
                        <span>{moment(customerInfo.created_at).format("Do MMM, YYYY")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-8">
              <div className="card card-bordered">
                <div className="card-inner">
                  <div className="analytic-data-group analytic-ov-group g-3 justify-content-md-evenly ">
                    <div className="analytic-data text-md-center m-1 pointer-cursor">
                      <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}?p=1&ps=${customerInfo.customer_phone_number}`}>
                        <div className="title text-success">Total Purchases</div>
                        <div className="amount fs-4"><small className="text-muted fw-light me-1">{businessInfo.currency}</small> {customerInfo.total_purchases_amount}</div>
                      </Link>
                    </div>
                    <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                    <div className="analytic-data text-md-center m-1 pointer-cursor">
                      <Link to={`${process.env.PUBLIC_URL}/sales/b/${businessID}?p=1&ps=${customerInfo.customer_phone_number}&t=invoice`}>
                        <div className="title text-danger">Balance Due</div>
                        <div className="amount fs-4"><small className="text-muted fw-light me-1">{businessInfo.currency}</small> {customerInfo.balance_due}</div>
                      </Link>
                    </div>
                    {/* <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                    <div className="analytic-data text-md-center m-1">
                      <div className="title text-muted">Credit Balance</div>
                      <div className="amount fs-4"><small className="text-muted fw-light me-1">{businessInfo.currency}</small> {customerInfo.credit_info.total_credit}</div>
                    </div> */}
                  </div>
                </div>
              </div>  
              <div className="card card-bordered">
                <Nav className="px-4" tabs>
                  {/* <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeTab === "purchases" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleTab("purchases");
                      }}
                    >
                      <span>Purchases</span>
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeTab === "items" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleTab("items");
                      }}
                    >
                      <span>Purchased Items</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeTab === "notes" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleTab("notes");
                      }}
                    >
                      <span>Notes</span>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeTab === "feedback" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleTab("feedback");
                      }}
                    >
                      <span>Feedbacks</span>
                    </NavLink>
                  </NavItem> */}
                  {/* <NavItem>
                    <NavLink
                      tag="a"
                      href="#tab"
                      className={classnames({ active: activeTab === "credit" })}
                      onClick={(ev) => {
                        ev.preventDefault();
                        toggleTab("credit");
                      }}
                    >
                      <span>Credit Logs</span>
                    </NavLink>
                  </NavItem> */}
                </Nav>
                <div className="card-inner">
                  <TabContent activeTab={activeTab}>
                    {/* <TabPane tabId="purchases">
                      <CustomerSalesTable history={history} businessID={businessID} customerPhoneNumber={customerInfo.customer_phone_number} currency={businessInfo.currency} />
                    </TabPane> */}
                    <TabPane tabId="items">
                      <CustomerPurchasedItemsTable history={history} businessID={businessID} customerID={customerInfo.customer_id} currency={businessInfo.currency} />
                    </TabPane>
                    <TabPane tabId="notes">
                      <CustomerNotes history={history} businessID={businessID} customerID={customerInfo.customer_id} currency={businessInfo.currency} />
                    </TabPane>
                    {/* <TabPane tabId="feedback">
                      <p>
                        Eu dolore ea ullamco dolore Lorem id cupidatat excepteur reprehenderit consectetur elit id dolor
                        proident in cupidatat officia. Voluptate excepteur commodo labore nisi cillum duis aliqua do. Aliqua
                        amet qui mollit consectetur nulla mollit velit aliqua veniam nisi id do Lorem deserunt amet. Culpa
                        ullamco sit adipisicing labore officia magna elit nisi in aute tempor commodo eiusmod.
                      </p>
                    </TabPane> */}
                    {/* <TabPane tabId="credit">
                      <CustomerCreditLogs history={history} businessID={businessID} businessInfo={businessInfo} customerInfo={customerInfo} currency={businessInfo.currency} setCustomerInfo={setCustomerInfo} />
                    </TabPane> */}
                  </TabContent>
                </div>
              </div>
            </div>

          </div>
        </Block>
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default CustomerProfile;