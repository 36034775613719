import React, { useState, useEffect } from "react";
import { Empty, Switch, Select, Popover } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import moment from 'moment';
import { getAxiosHeaders, getQueryParams, findUpper } from "../../../utils/Utils";
import { Badge, 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  UserAvatar,
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";

export const ServicesTable = ({ history, businessID, currency, ...props }) => {
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0,
    }, 
    services: []
  });
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
   const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    service_name: ''
  });
  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const selectAll = () => {
    setCheckAll(prevCheckAll => {
      if (prevCheckAll) {
        setCheckedBoxes([]);
      } else {
        const allIDs = tableData.services.map(item => item.service_id);
        setCheckedBoxes(allIDs);
      }
      return !prevCheckAll;
    });
  }

  const handleCheckboxChange = (value) => {
    setCheckedBoxes(prevBoxes => {
      if (prevBoxes.includes(value)) {
          return prevBoxes.filter(id => id !== value);
      } else {
          return [...prevBoxes, value];
      }
    });
    if (checkAll) {
        setCheckAll(false);
    }
  };

  const currentUrl = (filters) => {
    let service_name = filters.service_name.length > 0 ? `&ps=${filters.service_name}` : ``;
    
    if(service_name !== ''  && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${service_name}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getServices(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      service_name: ''
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getServices(params);
  }

  const filterServices = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getServices(filters);
  }

  const getServices = (filters) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + "/services", {
      params: {
        business_id: businessID,
        page: filters.page,
        service_name: filters.service_name
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0
          }, 
          services:[]
        });
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
          }, 
          services:[]
        });

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteServicesByIDs = () => {
    toggleModal('deleteLoadingModal');

    let _checkedBoxes = checkedBoxes;
    if(_checkedBoxes.length > 0){
      _checkedBoxes = _checkedBoxes.map((id) => parseInt(id));
    }

    axios.delete(baseURLs.API_URL + "/services/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        services: JSON.stringify(_checkedBoxes),
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_ids;
      let failedIDs = responseInfo.data.failed_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getServices(filters);
        toggleModal('successDeleteModal');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getServices(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `services` : `service`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `services` : `service`} failed to get deleted`);
        toggleModal('deleteErrorModal');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `services` : `service`}. The selected ${failedIDs.length > 1 ? `services were` : `service was`} not found.`);
        toggleModal('deleteErrorModal');
      }

      setCheckedBoxes([]);
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteServicesByFilter = () => {
    toggleModal('deleteLoadingModal');

    axios.delete(baseURLs.API_URL + "/services/by-filters", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        service_name: filters.service_name
      }
    })
    .then((response) => {
      getServices(filters);
      toggleModal('successDeleteModal');
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleSelectedRecordsAction = (action) => {
    if(!checkAll && checkedBoxes.length === 0){
      toggleModal('noSelectionAlertModal');
      return;
    }

    toggleModal('deleteAlertModal');    
  }

  const downloadServices = () => {
    setErrorMessage("");
    toggleModal('loadingModal');
    
    axios.get(baseURLs.API_URL + "/services/download", {
      params: {
        business_id: businessID,
        service_name: filters.service_name
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('No service found');        
        toggleModal('alertModal');
      } else {
        let responseInfo = response.data;
        setDownloadLink(responseInfo.data.download_link);
        toggleModal('downloadModal');
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }


  const handleSingleDelete = (serviceID) => {
    setCheckedBoxes([serviceID]);

    setTimeout(() => {
      toggleModal('deleteAlertModal');       
    }, 500);
  }

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let service_name = queryParams.hasOwnProperty('s') ? queryParams.s : '';

    let params = filters;
    params.page = page;
    params.service_name = service_name;

    setFilters(params);
    getServices(params);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Services` : `Service`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Services Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="service_name">
                                    Service Name
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="service_name" className="form-control form-control-lg" id="service_name" defaultValue={filters.service_name} />
                                  </div>
                                </div>
                                <div className="form-group mt-5">
                                  <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterServices();} } size="md">
                                    Apply Filter
                                  </Button>

                                  {
                                    hasFilters &&
                                    <Button className="ms-3 text-muted" color="lighter" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="md">
                                      Reset Filter
                                    </Button>
                                  }
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-light justify-content-start">
                              <span className="sub-text">Use the form above to filter your services.</span>
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getServices(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={downloadServices} title="Download">
                          <Icon name="download-cloud"></Icon>
                        </Button>
                        { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={"Preparing Services"} descriptionText={"Please wait while your services list is being prepared for download."} /> }
                        
                        { 
                          activeModal === "downloadModal" &&
                          <SuccessModal showModal={true} toggleModal={() => toggleModal('downloadModal')}
                            headerText={"Services Ready"} descriptionText={"Services CSV is ready for download."} 
                            leftButtonText={"Download"} leftButtonOnClick={() => {window.open(downloadLink, '_blank').focus(); toggleModal('downloadModal');}}
                          />
                        }
                        
                        {
                          activeModal === "alertModal" &&
                          <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
                            headerText={"Download Failed"} descriptionText={errorMessage}                       
                          />
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="p-0">
                {
                  tableData.services.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No services found</h5><span>Add a service using the button below.</span>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/services/b/${businessID}/add`}>
                        <button className="btn btn-outline-primary">Add a Service</button>
                      </Link>                    
                    </div>
                  </div>
                  :
                  <>      
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input
                              type="checkbox"
                              checked={checkAll}
                              onChange={selectAll}
                              className="custom-control-input table-checkbox"
                              id="select_all" />
                            <label className="custom-control-label" htmlFor="select_all"/>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text">Service</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Description</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Price</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Last Updated</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt text-capitalize" style={{letterSpacing: "normal"}}>
                                <li>
                                  <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSelectedRecordsAction('cancel')}>
                                    <Icon name="trash"></Icon>
                                    <span>Delete Selected Services</span>
                                    {
                                      activeModal === "deleteAlertModal" &&
                                      <AlertModal showModal={true} toggleModal={() => toggleModal('deleteAlertModal')}
                                        headerText={checkedBoxes.length > 1 ? `Delete Services` : `Delete Service`} 
                                        descriptionText={`${checkAll ? 
                                        `Are you sure you want to delete all ${tableData.meta.total_records} services. This action can not be reverted.` 
                                        :
                                        `Are you sure you want to delete ${checkedBoxes.length > 1 ? `these ${checkedBoxes.length} selected services` : `this selected service`}. This action can not be reverted.`}`}
                                        leftButtonText={`Delete`}
                                        leftButtonOnClick={checkAll ? deleteServicesByFilter : deleteServicesByIDs} />
                                    }
                                  </DropdownItem>
                                </li>
                              </ul>
                              {
                                activeModal === "noSelectionAlertModal" &&
                                <AlertModal showModal={true} toggleModal={() => toggleModal('noSelectionAlertModal')}
                                  headerText={`Error`} 
                                  descriptionText={`No service have been selected. Select a service and try again.`}
                                />
                              }

                              { activeModal === "deleteLoadingModal" && <LoadingModal showModal={true} headerText={`Deleting...`} descriptionText={`Please wait while the ${checkedBoxes.length > 1 ? `services are` : `service is`} being deleted`} /> }
                        
                              { 
                                activeModal === "successDeleteModal" &&
                                <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
                                  headerText={`Deleted`} descriptionText={`Service(s) deleted successfully.`} 
                                />
                              }
                              
                              {
                                activeModal === "deleteErrorModal" &&
                                <AlertModal showModal={true} toggleModal={() => toggleModal(null)}
                                  headerText={"Error"} descriptionText={errorMessage}                       
                                />
                              }
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.services.map((data, index) => {
                          return (
                            <DataTableItem key={index}>
                              <DataTableRow className="nk-tb-col-check">
                                <div className="custom-control custom-control-sm custom-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={checkAll || checkedBoxes.includes(data.service_id)}
                                    onChange={() => handleCheckboxChange(data.service_id)}
                                    className="custom-control-input table-checkbox"
                                    id={`item${data.service_id}`} 
                                  />
                                  <label className="custom-control-label" htmlFor={`item${data.service_id}`} />
                                </div>
                              </DataTableRow>
                              <DataTableRow>
                                <div className="user-card">
                                  <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                                  <div className="user-info">
                                    <span className={`tb-lead fw-normal`}>
                                      {data.service_name}{" "}
                                    </span>
                                    <div className="price d-md-none"><small>{currency} </small> {data.selling_price}</div>
                                  </div>
                                </div>
                              </DataTableRow>
                              <DataTableRow size="md" className="tb-tnx-desc">
                                <span>{data.description}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount">
                                  <small>{currency} </small> {data.selling_price}
                                </span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{data.updated_at && moment(data.updated_at).format("Do MMM YYYY")}</span>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/services/b/${businessID}/edit/${data.service_id}`}>
                                          <Icon name="edit-alt"></Icon>
                                          <span>Edit</span>
                                        </Link>
                                      </li>
                                      <li className="divider"></li>
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSingleDelete(data.service_id)}>
                                          <Icon name="trash"></Icon>
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                  </>
                }              
              </div>
            </div>
          </Card>
        </>
      }
    </Block>
  );
};
