import React, { useEffect, useState } from "react";
import { getAxiosHeaders, orderStatusOptions } from "../../../utils/Utils";
import { Link } from "react-router-dom";
import {
  Icon,
  Button
} from "../../../components/Component";
import { baseURLs } from '../../../utils/Constants';
import { 
  Alert,
  UncontrolledDropdown, 
  Dropdown, DropdownToggle, 
  DropdownMenu, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner } from "reactstrap";
import axios from 'axios';
import moment from "moment";
import DatePicker from "react-datepicker";
import { Input, InputNumber, Select, Checkbox, Form, Empty } from 'antd';
import {
  AlertModal,
  InfoModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { CopyToClipboard } from "react-copy-to-clipboard";
const { Option } = Select;
const { TextArea } = Input;

const Footer = ({ saleInfo, setSaleInfo, view = 'business', ...props }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [orderDueDate, setOrderDueDate] = useState(new Date());
  const [orderDueDateTime, setOrderDueDateTime] = useState(new Date());
  const [reminderDate, setReminderDate] = useState();
  const [reminderTime, setReminderTime] = useState();
  const [checked, setChecked] = useState(true);
  const [alertTeamChecked, setAlertTeamChecked] = useState(false);
  const [dueDate, setDueDate] = useState( saleInfo.due_date ? new Date(saleInfo.due_date) : new Date() );
  const [dueDateTime, setDueDateTime] = useState( saleInfo.due_date ? new Date(saleInfo.due_date) : new Date() );

  const [copyState, setCopyState] = useState(false);  
  const [loadingTitle, setLoadingTitle] = useState("");
  const [loadingMessage, setLoadingMessage] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [activeModal, setActiveModal] = useState(null);
  
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onCopyClick = () => {
    setCopyState(true);
    setTimeout(() => setCopyState(false), 2000);
  };

  const cancelSale = () => {
    setErrorMessage("");
    setLoadingTitle("Cancelling...");
    setLoadingMessage("Please wait while this sale is being cancelled...");
    toggleModal('loadingModal');

    axios.put(baseURLs.API_URL + `/sales/cancel/${saleInfo.sale_id}`, {
      business_id: saleInfo.business_id,
      alert_customer: checked ? "yes" : "no",
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      let saleData = saleInfo;
      saleData.status = responseInfo.data.status;    
      setSaleInfo({...saleData});

      setSuccessTitle("Cancelled Successfully");
      setSuccessMessage("The sale has been cancelled successfully.");
      toggleModal('successModal');

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('cancelModal');
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
        toggleModal('cancelModal');
      } 
    });
  }

  const refundSale = () => {
    setErrorMessage("");
    setLoadingTitle("Refunding...");
    setLoadingMessage("Please wait while this sale is being refunded...");
    toggleModal('loadingModal');

    axios.put(baseURLs.API_URL + `/sales/refund/${saleInfo.sale_id}`, {
      business_id: saleInfo.business_id,
      alert_customer: checked ? "yes" : "no",
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      
      let saleData = saleInfo;
      saleData.status = responseInfo.data.status;    
      setSaleInfo({...saleData});

      setSuccessTitle("Refunded Successfully");
      setSuccessMessage("The sale has been refunded successfully.");
      toggleModal('successModal');

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('refundModal');
      }catch(e){
        setErrorMessage("Error: Could not connect to server");   
        toggleModal('refundModal');     
      }
    });
  }

  const handlePrintOrderLabelFormSubmit = (_formData) => {
    setLoading(true);
    setErrorMessage("");

    axios.get(baseURLs.API_URL + `/orders/print-label/${saleInfo.sale_id}`, {
      headers: getAxiosHeaders().headers,
      params: {
        business_id: saleInfo.business_id,
        shipping_type: _formData.shipping_type,
        quantity: _formData.quantity,
      },
    })
    .then((response) => {
      let responseInfo = response.data;
      let labelLink = responseInfo.data.link;      
      window.open(labelLink, "_blank");
      form.resetFields();
      setSuccessTitle("Order Label Generated");
      setSuccessMessage("The order label has been generated successfully.");
      toggleModal('successModal');
      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setLoading(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
  }

  const sendReminder = () => {
    setErrorMessage("");
    setLoadingTitle("Sending...");
    setLoadingMessage("Please wait while we send a reminder alert to your customer...");
    toggleModal('loadingModal');

    axios.put(baseURLs.API_URL + `/sales/reminder/${saleInfo.sale_id}`, {
      business_id: saleInfo.business_id
    }, getAxiosHeaders())
    .then((response) => {

      setSuccessTitle("Reminder Sent");
      setSuccessMessage("A reminder has been sent to your customer successfully.");
      toggleModal('successModal');

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('reminderModal');
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
        toggleModal('reminderModal');
      }
    });
  }

  const deleteOrder = () => {
    setLoadingTitle("Deleting...");
    setLoadingMessage("Please wait while this sale's order is being deleted...");
    toggleModal('loadingModal');

    axios.delete(baseURLs.API_URL + `/orders/${saleInfo.sale_id}`, {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: saleInfo.business_id
      }
    })
    .then((response) => {      
      let saleData = saleInfo;
      saleData.order_log = [];   
      setSaleInfo({...saleData});

      setSuccessTitle("Deleted Successfully");
      setSuccessMessage("The sale's order has been deleted successfully.");
      toggleModal('successModal');

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteOrderModal');
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
        toggleModal('deleteOrderModal');
      }
    });
  }

  const updateDueDate = () => {
    if(!dueDate || !dueDateTime) {
      setErrorMessage("Due date is required");
      return;
    } else {
      setErrorMessage('');
    }

    let due_date = `${moment(dueDate).format("YYYY-MM-DD")} ${moment(dueDateTime).format("HH:mm")}`;
    
    setLoadingTitle("Updating...");
    setLoadingMessage("Please wait while the due date is being updated...");
    toggleModal('loadingModal');

    axios.put(baseURLs.API_URL + `/sales/due-date/${saleInfo.sale_id}`, {
      business_id: saleInfo.business_id,
      due_date: due_date
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      
      let saleData = saleInfo;
      saleData.due_date = due_date;    
      setSaleInfo({...saleData});

      setSuccessTitle("Due Date Updated");
      setSuccessMessage("The due date for this sale has been updated successfully.");
      toggleModal('successModal');

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('editDueDateModal');
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
        toggleModal('editDueDateModal');
      }
    });
  }

  const handleOrderFormSubmit = (_formData) => {
    setLoading(true);
    setErrorMessage("");

    axios.post(baseURLs.API_URL + `/orders/${saleInfo.sale_id}`, {
      business_id: saleInfo.business_id,
      status: _formData.status,
      due_date: `${moment(_formData.due_date).format("YYYY-MM-DD")} ${moment(_formData.due_date_time).format("HH:mm")}`,
      reminder_date: !(_formData.reminder_date && _formData.reminder_time) ? null : `${moment(_formData.reminder_date).format("YYYY-MM-DD")} ${moment(_formData.reminder_time).format("HH:mm")}`,
      customer_note: _formData.customer_note,
      assign_to: _formData.assign_to,
      alert_customer: _formData.alert_customer ? 'yes' : 'no',
      alert_team: _formData.alert_team ? 'yes' : 'no',
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;
      let saleData = saleInfo;      
      saleData.order_log = responseInfo.data.order_log;
      setSaleInfo({...saleData});

      setSuccessTitle("Added to Orders");
      setSuccessMessage("This sale has been added to your orders successfully.");
      toggleModal('successModal');
      setLoading(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(errorResponse.hasOwnProperty("errors")){
          form.setFields([
            {
              name: 'status',
              errors: errorResponse.errors.hasOwnProperty("status") ? [errorResponse.errors.status] : [],
            },
            {
              name: 'due_date',
              errors: errorResponse.errors.hasOwnProperty("due_date") ? [errorResponse.errors.due_date] : [],
            },
            {
              name: 'reminder_date',
              errors: errorResponse.errors.hasOwnProperty("reminder_date") ? [errorResponse.errors.reminder_date] : [],
            },
            {
              name: 'customer_note',
              errors: errorResponse.errors.hasOwnProperty("customer_note") ? [errorResponse.errors.customer_note] : [],
            },
            {
              name: 'assign_to',
              errors: errorResponse.errors.hasOwnProperty("assign_to") ? [errorResponse.errors.assign_to] : [],
            },
            {
              name: 'alert_customer',
              errors: errorResponse.errors.hasOwnProperty("alert_customer") ? [errorResponse.errors.alert_customer] : [],
            }
            
          ]);

        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        setLoading(false);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }
    });
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  
  return (
    <div className="fm-footer">
      <div className="fm-action text-center">
        {
          saleInfo.status === 'cancelled' || saleInfo.status === 'refunded' ?
          <Link to={`${process.env.PUBLIC_URL}/create-invoice-receipt/b/${saleInfo.business_id}?s=${saleInfo.sale_id}`}>
            <button className="btn btn-outline-gray btn-lg">
              <span>Create a Copy</span>
              <em className="icon ni ni-copy-fill"></em>
            </button>
          </Link>
          :
          <ul className="d-flex g-3 justify-content-center">
            <li>
              <div className="dropdown">
                <button className="btn btn-lg btn-primary" onClick={() => toggleModal("shareModal")}>
                  <span>Share</span>
                  <em className="icon ni ni-share"></em>
                </button>
                
              </div>
            </li>
            
            <li>
              <UncontrolledDropdown>
                <DropdownToggle className="dropdown-toggle btn btn-lg btn-outline-light" color="none">
                  <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu end>
                  <ul className="link-list-opt no-bdr">
                    {
                      saleInfo.order_log.length === 0 &&  
                      <li>
                        <DropdownItem tag="a" className="pointer-cursor" onClick={() => toggleModal("orderModal")}>
                          <Icon name="cart"></Icon>
                          <span>Add to Orders</span>
                        </DropdownItem>
                      </li>
                    }

                    {
                      saleInfo.amount_paid === '0.00' && saleInfo.sale_type !== 'receipt' &&
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/sales/b/${saleInfo.business_id}/edit/${saleInfo.sale_id}`}>
                          <Icon name="edit-alt"></Icon>
                          <span>Edit Sale</span>
                        </Link>
                      </li>
                    }

                    {
                      saleInfo.status !== 'receipt' && 
                      <>
                        <li>
                          <DropdownItem tag="a" className="pointer-cursor" onClick={() => toggleModal("editDueDateModal")} >
                            <Icon name="calendar-alt"></Icon>
                            <span>Edit Due Date</span>
                          </DropdownItem>
                        </li>

                        <li>
                        <DropdownItem tag="a" className="pointer-cursor" onClick={() => toggleModal("reminderModal")}>
                          <Icon name="bell"></Icon>
                          <span>Send Reminder</span>
                        </DropdownItem>
                        </li>
                      </>
                    }

                    
                    <li>
                      <DropdownItem tag="a" className="pointer-cursor" onClick={() => toggleModal("paymentLogsModal")}>
                        <Icon name="list"></Icon>
                        <span>Payment Logs</span>
                      </DropdownItem>
                    </li>
                    {
                      saleInfo.order_log.length > 0 &&  
                      <li>
                        <DropdownItem tag="a" className="pointer-cursor" onClick={() => toggleModal("orderLogsModal")}>
                          <Icon name="list-thumb"></Icon>
                          <span>Order Logs</span>
                        </DropdownItem>
                      </li>
                    }
                    <li className="divider"></li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/create-invoice-receipt/b/${saleInfo.business_id}?s=${saleInfo.sale_id}`}>
                        <Icon name="copy"></Icon>
                        <span>Create a Copy</span>
                      </Link>
                    </li>
                    <li>
                      <a href={`${saleInfo.a4_print_link}`} target="_blank">
                        <Icon name="printer"></Icon>
                        <span>A4 Print</span>
                      </a>
                    </li>
                    <li>
                      <a href={`${saleInfo.thermal_print_link}`} target="_blank">
                        <Icon name="notes-alt"></Icon>
                        <span>Thermal Print</span>
                      </a>
                    </li> 
                    {
                      saleInfo.order_log.length > 0 &&
                      <li>
                        <DropdownItem tag="a" className="pointer-cursor" onClick={() => toggleModal("orderLabelModal")}>
                          <Icon name="article"></Icon>
                          <span>Print Order Labels</span>
                        </DropdownItem>
                      </li> 
                    }                   
                    <li className="divider"></li>
                    <li>
                      <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => toggleModal("refundModal")}>
                        <Icon name="curve-up-left"></Icon>
                        <span>Refund Sale</span>
                      </DropdownItem>
                    </li>
                    <li>
                      <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => toggleModal("cancelModal")}>
                        <Icon name="na"></Icon>
                        <span>Cancel Sale</span>
                      </DropdownItem>
                    </li>
                    {
                      saleInfo.order_log.length > 0 &&
                      <li>
                        <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => toggleModal("deleteOrderModal")}>
                          <Icon name="trash-empty"></Icon>
                          <span>Delete Order</span>
                        </DropdownItem>
                      </li>
                    }
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            </li>
          </ul>
        }
      </div>

      {/* share sale modal */}
      {
        activeModal === "shareModal" &&
        <Modal isOpen={true} toggle={() => toggleModal('shareModal')} id="share-modal">
          <button className="close" onClick={() => toggleModal('shareModal')}><em className="icon ni ni-cross"></em></button>
          <ModalBody className="modal-body-lg text-center">
            <div className="nk-modal">
              <h4 className="nk-modal-title fw-normal ccap">Share {saleInfo.status}</h4>
              <div className="nk-modal-text">
                <span className="sub-text-sm">Copy and share the link to your customer directly.</span>
                <div className="nk-refwg-url mt-1">
                  <div className="form-control-wrap">
                    <CopyToClipboard text={saleInfo.share_link} onCopy={onCopyClick}>
                      {
                        copyState ?
                        <div className="form-clip clipboard-init text-success">
                          <em className="clipboard-icon icon ni ni-copy"></em> 
                          <span className="clipboard-text">Copied</span>
                        </div>
                        :
                        <div className="form-clip clipboard-init">
                          <em className="clipboard-icon icon ni ni-copy"></em> 
                          <span className="clipboard-text">Copy</span>
                        </div>
                      }
                      
                    </CopyToClipboard>
                    <div className="form-icon" style={{left: '1px'}}><em className="icon ni ni-link-alt"></em></div>
                    <input type="text" className="form-control copy-text" id="refUrl" value={saleInfo.share_link} />
                  </div>
                </div>
                <div className="text-center pt-5"><h6 className="overline-title overline-title-sap"><span>OR</span></h6></div>
              </div>
              <div className="d-flex mb-4 pt-3" style={{justifyContent: "space-around"}}>
                <div className="d-md-none">
                  <a href={`whatsapp://send?phone=${saleInfo.customer_phone_number ?? ``}&text=${encodeURIComponent(`Your ${saleInfo.status} from ${saleInfo.business_name}. \nClick to view: ${saleInfo.share_link}`)}`} data-action="share/whatsapp/share" target="_blank">
                    <div className="preview-icon-wrap"><em className="icon ni ni-whatsapp text-teal"></em></div>
                    <span className="preview-icon-name">Whatsapp</span>
                  </a>
                </div>
                <div className="d-none d-md-block">
                  <a href={`https://api.whatsapp.com/send?phone=${saleInfo.customer_phone_number ?? ``}&text=${encodeURIComponent(`Your ${saleInfo.status} from ${saleInfo.business_name}. \nClick to view: ${saleInfo.share_link}`)}`} data-action="share/whatsapp/share" target="_blank">
                    <div className="preview-icon-wrap"><em className="icon ni ni-whatsapp text-teal"></em></div>
                    <span className="preview-icon-name">Whatsapp</span>
                  </a>
                </div>
                <div>
                  <a href={`https://t.me/share/url?url=${saleInfo.receipt_url}&text=Your ${saleInfo.status} from ${saleInfo.business_name}. Click on the link to view`} target="_blank">
                    <div className="preview-icon-wrap"><em className="icon ni ni-telegram text-azure"></em></div>
                    <span className="preview-icon-name">Telegram</span>
                  </a>
                </div>
              </div>
              <div className="nk-modal-action mt-5">
                <Button color="light" size="lg" className="btn-mw m-1" onClick={() => toggleModal('shareModal')}>
                  Close
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      }

      {/* cancel sale modal */}
      {
        activeModal === "cancelModal" &&
        <AlertModal 
          index="cancel"
          showModal={true} 
          toggleModal={() => toggleModal('cancelModal')}
          headerText={`Cancel this ${saleInfo.status}?`}
          descriptionText={`Are you sure you want to cancel this ${saleInfo.status}? This action can not be reverted.`}
          leftButtonText={`Cancel`}
          leftButtonOnClick={cancelSale}>
          <div className="form-group mt-5">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                checked={checked}
                onChange={() => setChecked(!checked)}
                id="cancel_alert_customer" />
              <label className="custom-control-label" htmlFor="cancel_alert_customer">
                Alert customer of refund status
              </label>
            </div>
          </div>
        </AlertModal>
      
      }

      {/* refund sale modal */}
      {
        activeModal === "refundModal" &&
        <AlertModal 
          index="refund"
          showModal={true} 
          toggleModal={() => toggleModal('refundModal')}
          headerText={`Refund this ${saleInfo.status}?`}
          descriptionText={`Are you sure you want to refund this ${saleInfo.status}? This action can not be reverted.`}
          leftButtonText={`Refund`}
          leftButtonOnClick={refundSale}>
            {
              errorMessage.length > 0 && <p className="text-danger mt-4">{errorMessage}</p>
            }
          <div className="form-group mt-5">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                checked={checked}
                onChange={() => setChecked(!checked)}
                id="refund_alert_customer" />
              <label className="custom-control-label" htmlFor="refund_alert_customer">
                Alert customer of refund status
              </label>
            </div>
          </div>
        </AlertModal>
      }

      {/* send reminder modal */}
      {
        activeModal === "reminderModal" &&
        <InfoModal 
          index="reminder"
          showModal={true} 
          toggleModal={() => toggleModal('reminderModal')}
          headerText={`Send a Reminder?`}
          descriptionText={`Will you like to send a reminder to your customer for this ${saleInfo.status}?`}
          leftButtonText={`Send Reminder`}
          leftButtonOnClick={sendReminder}
          rightButtonText={`Close`}
          rightButtonOnClick={() => toggleModal('reminderModal')} >
          {
            errorMessage.length > 0 && <p className="text-danger mt-4">{errorMessage}</p>
          }
        </InfoModal>
      }

      {/* add to orders modal */}
      {
        activeModal === "orderModal" &&
        <Modal backdrop="static" isOpen={true} id="order-modal">
          <ModalHeader
            toggle={() => toggleModal('orderModal')}
            close={
              <button className="close" onClick={() => toggleModal('orderModal')}>
                <Icon name="cross" />
              </button>
            }
          >
            Add to Orders
          </ModalHeader>
            <Form name="add-order"
              form={form} className="is-alter"
              onFinish={handleOrderFormSubmit}
              initialValues={{
                alert_customer: true,
                alert_team: false,
                status: '',
                assign_to: '',
                customer_note: '',
                due_date: orderDueDate,
                due_date_time: orderDueDateTime,
                order_status: '',
                reminder_date: reminderDate,
                reminder_time: reminderTime        
              }}
              onFinishFailed={onFinishFailed}>
              <ModalBody>
                {errorMessage && (
                  <div className="mb-3">
                    <Alert color="danger" className="alert-icon">
                      {" "}
                      <Icon name="alert-circle" /> {errorMessage}{" "}
                    </Alert>
                  </div>
                )}
                
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Order Status <span className="text-danger">*</span></label>
                          </div>
                          <div className="form-control-wrap">
                          <Form.Item name={'status'}
                            noStyle
                            rules={[
                              {required: true, message: 'Order status is required',}
                            ]}>
                            <Select required size="large" 
                              name="status"
                              placeholder="Select order status"
                              style={{ width: "100%" }}
                              options={orderStatusOptions} 
                              showSearch />   
                            </Form.Item>                       
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="form-label-group">
                            <label className="form-label">Due Date <span className="text-danger">*</span></label>
                        </div>
                        <div className="row g-2">
                          <div className="col-7">
                            <Form.Item>
                              <Form.Item name={'due_date'}
                                noStyle
                                rules={[
                                  {required: true, message: 'Date is required',}
                                ]}>
                                <DatePicker
                                  selected={orderDueDate}
                                  dateFormat="dd-MMM-yyyy"
                                  onChange={date => {
                                    setOrderDueDate(date);
                                    form.setFieldsValue({due_date: date}); // Update Ant Design Form
                                  }}
                                  shouldCloseOnSelect={false}
                                  showMonthDropdown
                                  showYearDropdown
                                  isClearable
                                  className="form-control form-control-lg date-picker"
                                />
                              </Form.Item>
                            </Form.Item>
                          </div>
                          <div className="col-5">
                            <Form.Item>
                              <Form.Item name={'due_date_time'}
                                noStyle
                                rules={[
                                  {required: true, message: 'time is required',}
                                ]}>
                                <DatePicker
                                  selected={orderDueDateTime}
                                  dateFormat="h:mm aa"
                                  onChange={time => {
                                    setOrderDueDateTime(time);
                                    form.setFieldsValue({due_date_time: time}); // Update Ant Design Form
                                  }}
                                  shouldCloseOnSelect={false}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  className="form-control form-control-lg date-picker"
                                />
                              </Form.Item>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="form-label-group">
                            <label className="form-label">Set Reminder Date <span className="text-muted">(optional)</span></label>
                        </div>
                        <div className="row g-2">
                          <div className="col-7">
                            <Form.Item>
                              <Form.Item name={'reminder_date'}
                                rules={[
                                  {required: false}
                                ]}
                                noStyle>
                                <DatePicker
                                  selected={reminderDate}
                                  dateFormat="dd-MMM-yyyy"
                                  onChange={date => {
                                    setReminderDate(date);
                                    form.setFieldsValue({reminder_date: date}); // Update Ant Design Form
                                  }}
                                  shouldCloseOnSelect={false}
                                  showMonthDropdown
                                  showYearDropdown
                                  isClearable
                                  className="form-control form-control-lg date-picker"
                                />
                              </Form.Item>
                            </Form.Item>
                          </div>
                          <div className="col-5">
                            <Form.Item>
                              <Form.Item name={'reminder_time'}
                                rules={[
                                  {required: false}
                                ]}
                                noStyle>
                                <DatePicker
                                  selected={reminderTime}
                                  dateFormat="h:mm aa"
                                  onChange={time => {
                                    setReminderTime(time)
                                    form.setFieldsValue({reminder_time: time}); // Update Ant Design Form
                                  }}
                                  shouldCloseOnSelect={false}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  className="form-control form-control-lg date-picker"
                                />
                              </Form.Item>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Note to customer <span className="text-muted">(optional)</span></label>
                          </div>
                          <div className="form-control-wrap">
                          <Form.Item name={'customer_note'}
                            noStyle
                            rules={[
                              {required: false}
                            ]}>
                              <TextArea rows={4} placeholder="Note for customer" />
                            </Form.Item>                       
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Assign To <span className="text-muted">(optional)</span></label>
                          </div>
                          <div className="form-control-wrap">
                          <Form.Item name={'assign_to'}
                            noStyle
                            rules={[
                              {required: false}
                            ]}>
                            <Select required size="large"
                              placeholder="Select order status"
                              style={{ width: "100%" }}
                              allowClear
                              showSearch >
                              {saleInfo.team_members.map(member => (
                                <Option key={member.uuid} value={member.uuid}>
                                  {member.full_name}
                                </Option>
                              ))}
                            </Select>   
                            </Form.Item>                       
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <Form.Item name="alert_customer" valuePropName="checked">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={checked}
                              onChange={() => {
                                setChecked(!checked);
                                form.setFieldValue('alert_customer', !checked);
                              }}
                              id="new_order_alert_customer" />
                            <label className="custom-control-label" htmlFor="new_order_alert_customer">
                              Alert customer of order status
                            </label>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                    <div className="col-md-6">
                      <Form.Item name="alert_team" valuePropName="checked">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={alertTeamChecked}
                              onChange={() => {
                                setAlertTeamChecked(!alertTeamChecked);
                                form.setFieldValue('alert_team', !alertTeamChecked);
                              }}
                              id="new_order_alert_team" />
                            <label className="custom-control-label" htmlFor="new_order_alert_team">
                              Alert Team Members
                            </label>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  </div>
              </ModalBody>
              <ModalFooter className="bg-lighter justify-content-center">
                <div className="text-center">
                  <Button className="m-1" disabled={loading} type="submit" color="primary" size="md" >
                    {loading ? <Spinner size="sm" color="light" /> : "Add to Orders"}
                  </Button>
                  <Button className="m-1" disabled={loading} type="button" onClick={() => toggleModal('orderModal')} color="light" size="md" >
                    Close
                  </Button>
                </div>
              </ModalFooter>
            </Form>
        </Modal>
      }
      
      {/* print order label modal */}
      {
        activeModal === "orderLabelModal" &&
        <Modal backdrop="static" isOpen={true} id="edit-modal">
          {/* <button className="close" onClick={() => toggleModal('orderLabelModal')}><em className="icon ni ni-cross"></em></button> */}
          <ModalBody className="modal-body-lg text-center">
            <div className="nk-modal">
              <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-article bg-light"></Icon>
              <h4 className="nk-modal-title fw-normal">Print Order Label</h4>
              <div className="nk-modal-text">
                {errorMessage && (
                  <div className="mb-3">
                    <Alert color="danger" className="alert-icon">
                      {" "}
                      <Icon name="alert-circle" /> {errorMessage}{" "}
                    </Alert>
                  </div>
                )}
                <Form name="print-order-label"
                  form={form} className="is-alter"
                  initialValues={
                    {shipping_type: 'standard', quantity: 1}
                  }
                  onFinish={handlePrintOrderLabelFormSubmit}
                  onFinishFailed={onFinishFailed}>
                  <div className="row text-start">
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                            <label className="form-label">Shipping Type <span className="text-danger">*</span></label>
                          </div>
                          <div className="form-control-wrap">
                          <Form.Item name={'shipping_type'}
                            noStyle
                            rules={[
                              {required: true, message: 'shipping type is required',}
                            ]}>
                            <Select required size="large" 
                              name="status"
                              placeholder="Select shipping type"
                              style={{ width: "100%" }}
                              options={[
                                {value: 'standard', label: 'Standard Shipping'},
                                {value: 'express', label: 'Express Shipping'},
                              ]} 
                              showSearch />   
                            </Form.Item>                       
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <Form.Item>
                          <div className="form-label-group">
                              <label className="form-label">Number of Labels <span className="text-danger">*</span></label>
                          </div>
                          <Form.Item name={'quantity'}
                            noStyle
                            rules={[
                              {required: true, message: 'Quantity is required'}
                            ]}>
                            <InputNumber defaultValue={1} min={1} step={1} size="large" className="w-100" />                                        
                          </Form.Item> 
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="mt-3 text-center">
                        <Button className="m-1" disabled={loading} type="submit" color="primary" size="lg" >
                          {loading ? <Spinner size="sm" color="light" /> : "Submit"}
                        </Button>
                        <Button className="m-1" disabled={loading} type="button" onClick={() => toggleModal('orderLabelModal')} color="light" size="lg" >
                          Close
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      }

      {/* edit due date modal */}
      {
        activeModal === "editDueDateModal" &&
        <Modal backdrop="static" isOpen={true} id="edit-modal">
          <button className="close" onClick={() => toggleModal('editDueDateModal')}><em className="icon ni ni-cross"></em></button>
          <ModalBody className="modal-body-lg text-center">
            <div className="nk-modal">
              <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-calendar-alt bg-light"></Icon>
              <h4 className="nk-modal-title fw-normal">Edit Due Date</h4>
              <div className="nk-modal-text">
                {/* <div className="caption-text">
                  {descriptionText}
                </div> */}
                <div className="form-group">
                  <div className="form-label-group">
                      <label className="form-label">Due Date <span className="text-danger">*</span></label>
                  </div>
                  <div className="row g-2">
                    <div className="col-7">
                      <DatePicker
                        selected={dueDate}
                        dateFormat="dd-MMM-yyyy"
                        onChange={date => { setDueDate(date); }}
                        shouldCloseOnSelect={false}
                        showMonthDropdown
                        showYearDropdown
                        isClearable
                        className="form-control form-control-lg date-picker"
                      />
                    </div>
                    <div className="col-5">
                      <DatePicker
                        selected={dueDateTime}
                        dateFormat="h:mm aa"
                        onChange={time => { setDueDateTime(time); }}
                        shouldCloseOnSelect={false}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        className="form-control form-control-lg date-picker"
                      />
                    </div>
                  </div>
                  <p className="text-danger text-start mt-1">{errorMessage}</p>
                </div>
                {/* <div className="form-group text-start">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                      id="due_date_alert_customer" />
                    <label className="custom-control-label" htmlFor="due_date_alert_customer">
                      Alert Customer
                    </label>
                  </div>
                </div> */}
              </div>
              <div className="nk-modal-action mt-5">
                <Button color="dark" size="lg" className="btn-mw m-1" onClick={updateDueDate}>
                  Submit
                </Button>

                <Button color="light" size="lg" className="btn-mw m-1" onClick={() => toggleModal('editDueDateModal')}>
                  Close
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      }

      {/* payment logs modal */}
      {
        activeModal === "paymentLogsModal" &&
        <Modal backdrop="static" isOpen={true} id="payment-logs-modal">
          <ModalHeader
            toggle={() => toggleModal('paymentLogsModal')}
            close={
              <button className="close" onClick={() => toggleModal('paymentLogsModal')}>
                <Icon name="cross" />
              </button>
            } >
            Payment Logs
          </ModalHeader>
          <ModalBody className="modal-body-md">
            {
              saleInfo.payment_log.length === 0 ?
              <div className="text-center m-5">
                <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                <div className="price-plan-info">
                  <h5 className="title fw-normal">No payment logs</h5>
                  <span>Payments made on this sale will show here.</span>
                </div>
              </div>
              :
              <ul className="timeline-list">
                {saleInfo.payment_log.map((log, index) => {
                  return (
                    <li className="timeline-item" key={index}>
                      <div className={`timeline-status bg-primary ${index !== 0 ? "is-outline" : ""}`}></div>
                      <div className="timeline-data">
                        <h6 className="timeline-title text-primary">Made payment of <small>{saleInfo.currency}</small> {log.amount_paid}</h6>
                        <div className="timeline-des">
                          <p>Amount Received: <b><small>{saleInfo.currency}</small> {log.amount_received}</b></p>
                          <p>Payment Type: <b className="text-capitalize">{log.payment_type}</b></p>
                          <p>Balance Due: <b><small>{saleInfo.currency}</small> {log.balance_remaining}</b></p>
                          <p className="fs-12px">Updated By: <b>{log.updated_by}</b></p>
                          <span className="time"><Icon name="clock"></Icon> {moment(log.created_at).format("Do MMM YYYY, hh:mm a")}</span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            }
          </ModalBody>
        </Modal>
      }

      {/* order logs modal */}
      {
        activeModal === "orderLogsModal" &&
        <Modal backdrop="static" isOpen={true} id="order-logs-modal">
          <ModalHeader
            toggle={() => toggleModal('orderLogsModal')}
            close={
              <button className="close" onClick={() => toggleModal('orderLogsModal')}>
                <Icon name="cross" />
              </button>
            } >
            Order Logs
          </ModalHeader>
          <ModalBody className="modal-body-md">
            <ul className="timeline-list">
              {saleInfo.order_log.map((log, index) => {
                let icon = <Icon name="clock" className="fs-20px px-1"></Icon>;
                let status = view === "business" ? "Outstanding" : "Queued";

                switch (log.status) {
                  case 'outstanding':
                    status = view === "business" ? "Outstanding" : "Queued";
                    icon = <Icon name="cart" className="fs-20px px-1"></Icon>;
                    break;

                  case 'pending_payment':
                    status = "Pending Payment";
                    icon = <Icon name="money" className="fs-20px px-1"></Icon>;
                    break;

                  case 'processing':
                    status = "Processing";
                    icon = <Icon name="update" className="fs-20px px-1"></Icon>;
                    break;

                  case 'on_hold':
                    status = "On Hold";
                    icon = <Icon name="disk" className="fs-20px px-1"></Icon>;
                    break;
                
                  case 'ready_for_pickup':
                    status = "Ready for Pickup";
                    icon = <Icon name="package" className="fs-20px px-1"></Icon>;
                    break;

                  case 'in_transit':
                    status = "In Transit";
                    icon = <Icon name="truck" className="fs-20px px-1"></Icon>;
                    break;
                
                  case 'delivered':
                    status = "Delivered";
                    icon = <Icon name="check-c" className="fs-20px px-1"></Icon>;
                    break;
                  
                  default:
                    status = view === "business" ? "Outstanding" : "Queued";
                    break;
                }

                return (
                  <li className="timeline-item" key={index}>
                    <div className={`timeline-status bg-primary ${index !== 0 ? "is-outline" : ""}`}></div>
                    <div className="timeline-data">
                      <h6 className="timeline-title text-primary">{icon} {status}</h6>
                      <div className="timeline-des">
                        <p>Due Date: <b>{moment(log.due_date).format("Do MMM YYYY, hh:mm a")}</b></p>
                        <p>Notes: <b>{log.notes}</b></p>
                        <p className="fs-12px">Updated By: <b>{log.updated_by}</b></p>
                        <span className="time"><Icon name="clock"></Icon> {moment(log.updated_at).format("Do MMM YYYY, hh:mm a")}</span>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </ModalBody>
        </Modal>
      }

      {/* Loading modal */}
      {
        activeModal === "loadingModal" &&
        <LoadingModal 
          showModal={true}
          toggleModal={() => toggleModal('loadingModal')}
          headerText={loadingTitle}
          descriptionText={loadingMessage} />
      }
      
      {/* Error modal */}
      {
        activeModal === "errorModal" &&
        <AlertModal 
          showModal={true}
          toggleModal={() => toggleModal('errorModal')}
          headerText={errorTitle}
          descriptionText={errorMessage} />
      }

      {/* success modal */}
      {
        activeModal === "successModal" &&
        <SuccessModal 
          showModal={true}
          toggleModal={() => toggleModal('successModal')}
          headerText={successTitle}
          descriptionText={successMessage} />
      }

      {/* delete order modal */}
      {
        activeModal === "deleteOrderModal" &&
        <AlertModal 
          index="delete-order"
          showModal={true} 
          toggleModal={() => toggleModal('deleteOrderModal')}
          headerText={`Delete this Order?`}
          descriptionText={`Are you sure you want to delete this order? This action can not be reverted.`}
          leftButtonText={`Delete`}
          leftButtonOnClick={deleteOrder} >
          {
            errorMessage.length > 0 && <p className="text-danger mt-4">{errorMessage}</p>
          }
        </AlertModal>
      }
    </div>
  );
};


export default Footer;