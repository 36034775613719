import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import imagePlaceholder from "../images/product_placeholder.png";
import classnames from "classnames";
import { 
  Card,
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  UncontrolledDropdown, 
  DropdownItem, 
  Spinner, 
  Alert,
  Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  UserAvatar,
  BackTo,
} from "../components/Component";
import { Empty } from 'antd';
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders, getQueryParams,  findUpper} from "../utils/Utils";
import { Link } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { SalesTable } from "./components/tables/SalesTable";
import { SuccessModal } from "./components/AlertModals";
import { Input, Select, InputNumber, Divider, Popover} from 'antd';
import { ProductPurchasesTable } from "./components/tables/ProductPurchasesTable";
import { ProductStockLogsTable } from "./components/tables/ProductStockLogsTable";
import { ProductDamagedLogsTable } from "./components/tables/ProductDamagedLogsTable";

const ProductDetails = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const [loading, setLoading] = useState(true);
  const [businessID, setBusinessID] = useState("");
  const [productID, setProductID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [productInfo, setProductInfo] = useState({});
  const [productFound, setProductFound] = useState(false);
  const [activeTab, setActiveTab] = useState("purchases");
  const [activeModal, setActiveModal] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };


  const getProductInfo = (businessID, productID) => {

    axios.get(baseURLs.API_URL + `/products/variant/${productID}`, {
      params: {
        business_id: businessID
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let productInfo = responseInfo.data.product_info;
        
        // update product info data 
        setProductInfo(productInfo);
        setProductFound(true);
      }

      setLoading(false);
    }).catch((error) => {
      console.log(error);
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoading(false);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    setLoading(true);
    const _businessID = parseInt(match.params.businessID, 10);
    const _productID = parseInt(match.params.productID, 10);
    if ( !Number.isInteger(_businessID) && !Number.isInteger(_productID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', _businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == _businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(_businessID);
    setProductID(_productID);
    getProductInfo(_businessID, _productID);
  }, [match.params.businessID, match.params.productID]);


  return (<React.Fragment>
    <Head title="Product Details" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
        <BlockHead className="mb-4" size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockDes className="text-soft">
                <p>{businessInfo.name}</p>
              </BlockDes>
              <BlockTitle page tag="h3">
                Product Details
              </BlockTitle>
              
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    {
                      productFound &&
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}/edit/${productInfo.parent_product_id}`}>
                          <Button color="primary">
                            <Icon name="edit-alt"></Icon>
                            <span>Edit</span>
                          </Button>
                        </Link>
                      </li>
                    }
                    <li>
                      <BackTo className="mb-3" history={history} link={`/products/b/${businessID}`} icon="arrow-left" type="button">Go Back</BackTo>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </div>
        </BlockHead>
        <Block className="wide-sm m-auto">
          {errorMessage && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                {" "}
                <Icon name="alert-circle" /> {errorMessage}{" "}
              </Alert>
            </div>
          )}
        </Block>

        {/* success modal */}
        { 
          activeModal === "successModal" &&
          <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
            headerText={`Updated`} descriptionText={`The product's information been updated successfully.`} 
          />
        }

        {
          productFound ? 
          <Block>
            <div className="row g-gs">
              <div className="col-xl-4">
                <div className="card card-bordered">
                  <div className="card-inner-group">
                    <div className="card-inner">
                      <div className="user-card user-card-s2">
                        <UserAvatar className="sq w-200px h-200px" theme="lighter" text={findUpper(productInfo.product_name)} image={productInfo.images.length > 0 ? productInfo.images[0].url : imagePlaceholder} imagePlaceholder={imagePlaceholder} />
                        <div className="user-info">
                          <h5>{productInfo.product_name}</h5>                        
                        </div>
                      </div>
                      <div className="reset-style text-start" dangerouslySetInnerHTML={{ __html: productInfo.description }} />
                    </div>
                    
                    <div className="card-inner">
                      <div className="row text-center" style={{whiteSpace: "nowrap"}}>
                        <div className="col-6">
                          <div className="profile-stats pointer-cursor">
                            <span className="amount text-primary">{productInfo.quantity}</span>
                            <span className="sub-text">Quantity</span>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="profile-stats pointer-cursor">
                            <span className="amount text-primary"><small className="me-1">{businessInfo.currency}</small>{productInfo.selling_price}</span>
                            <span className="sub-text">Selling Price</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-inner card-inner-sm text-center d-block d-lg-none">
                      <button className={`btn ${showMoreDetails ? `btn-light` : `btn-primary`} btn-block`} onClick={() => {setShowMoreDetails(!showMoreDetails)}}>{`${showMoreDetails ? `Hide`:`View`}`} Details</button>
                    </div>

                    <div className={`card-inner  ${showMoreDetails ? `d-block` : `d-none d-lg-block`} `}>
                      <h6 className="overline-title mb-2">Details</h6>
                      <div className="row g-3">
                        <div className="col-sm-6 col-xl-12">
                          <span className="sub-text">Cost Price:</span>
                          <span className="text-primary">{productInfo.cost_price}</span>
                        </div>
                        <div className="col-sm-6 col-xl-12">
                          <span className="sub-text">Previous Price:</span>
                          <span className="text-primary">{productInfo.previous_price}</span>
                        </div>
                        <div className="col-sm-6 col-xl-12">
                          <span className="sub-text">Stock Limit:</span>
                          <span className="text-primary">{productInfo.stock_limit}</span>
                        </div>
                        <div className="col-sm-6 col-xl-12">
                          <span className="sub-text">Stock Status:</span>
                          {
                            productInfo.stock_status === 'in_stock' ?
                            <span className={`${ productInfo.quantity > productInfo.restock_level ? `text-success` : `text-warning`}`}>In Stock</span>
                            :
                            <span className="text-danger">Out of Stock</span>
                          }
                        </div>
                        <div className="col-sm-6 col-xl-12">
                          <span className="sub-text">Restock Level:</span>
                          <span className="text-primary">{productInfo.restock_level}</span>
                        </div>
                        <div className="col-sm-6 col-xl-12">
                          <span className="sub-text">SKU:</span>
                          <span className="text-primary">{productInfo.SKU}</span>
                        </div>
                        <div className="col-sm-6 col-xl-12">
                          <span className="sub-text">Barcode:</span>
                          <span className="text-primary">{productInfo.barcode}</span>
                        </div>
                        <div className="col-sm-6 col-xl-12">
                          <span className="sub-text">Product Location:</span>
                          <span className="text-primary">{productInfo.product_location}</span>
                        </div>
                        <div className="col-sm-6 col-xl-12">
                          <span className="sub-text">Created At:</span>
                          <span className="text-primary">{moment(productInfo.created_at).format("Do MMM, YYYY")}</span>
                        </div>

                        <div className="col-md-12">
                          <hr className="m-3 mx-0" />
                          <h6 className="overline-title text-primary-alt mt-5">Storefront</h6>
                        </div>

                        <div className="col-sm-6 col-xl-12">
                          <span className="sub-text">Category:</span>
                          <span className="badge bg-outline-primary rounded-pill">{productInfo.category}</span>
                        </div>
                        <div className="col-sm-6 col-xl-6">
                          <span className="sub-text">Show on Storefront:</span>
                          <span>{productInfo.show_on_storefront}</span>
                        </div>
                        <div className="col-sm-6 col-xl-6">
                          <span className="sub-text">Featured Product:</span>
                          <span>{productInfo.featured_product}</span>
                        </div>

                        <div className="col-sm-6 col-xl-12">
                          <span className="sub-text">Tags:</span>
                          <span>
                            {
                              productInfo.tags.length > 0 ?
                              <ul className="preview-list">
                                {
                                  productInfo.tags.map((tag, index) => {
                                    return (
                                      <li className="preview-item" key={index}>
                                        <span className="badge bg-outline-gray rounded-pill">{tag}</span>
                                      </li>
                                    );
                                  })
                                }
                              </ul>
                              :
                              <span className="text-muted"></span>
                            }
                          </span>
                        </div>

                        <div className="col-md-12">
                          <hr className="m-3 mx-0" />
                          <h6 className="overline-title text-primary-alt mt-5">Expiration</h6>
                        </div>

                        <div className="col-sm-6 col-xl-12">
                          {
                            productInfo.expirations.length > 0 &&
                            <div className="card-bordered card my-3">
                              <ul className="nk-support">
                                {
                                  productInfo.expirations.map(expiration => (
                                    <li className="nk-support-item p-3" key={expiration.batch_number}>
                                      <div className="nk-support-content">
                                        <div className="title m-0">
                                          <span>{expiration.product_name}</span>
                                        </div>
                                        <span className="time"><Icon name="calendar-alt" /> {expiration.expiration_date}</span>
                                        <span className="time"><Icon name="bell" /> {expiration.alert_date}</span>
                                      </div>
                                    </li>
                                  ))
                                }
                              </ul>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-8">
                
                <div className="card card-bordered">
                  <Nav className="px-4" tabs>
                    <NavItem>
                      <NavLink
                        tag="a"
                        href="#tab"
                        className={classnames({ active: activeTab === "purchases" })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleTab("purchases");
                        }}
                      >
                        <span>Purchases</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag="a"
                        href="#tab"
                        className={classnames({ active: activeTab === "stock" })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleTab("stock");
                        }}
                      >
                        <span>Stock Logs</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag="a"
                        href="#tab"
                        className={classnames({ active: activeTab === "damages" })}
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggleTab("damages");
                        }}
                      >
                        <span>Damages</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <div className="card-inner">
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="purchases">
                        <ProductPurchasesTable history={history} businessID={businessID} productID={productInfo.product_id} currency={businessInfo.currency} />
                      </TabPane>
                      <TabPane tabId="stock">
                        <ProductStockLogsTable history={history} businessID={businessID} productID={productInfo.product_id} currency={businessInfo.currency} />
                      </TabPane>
                      <TabPane tabId="damages">
                        <ProductDamagedLogsTable history={history} businessID={businessID} productID={productInfo.product_id} currency={businessInfo.currency} />
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>

            </div>
          </Block>
          :
          <Block className="wide-sm m-auto">
            <Card className="card-bordered">
              <div className="card-inner">
                <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">Product not found</h5>
                      <span>The product you are looking for could not be found.</span>
                      <p className="text-soft">If you need help, you can reach us on our <a href={baseURLs.LIVE_CHAT}>live chat</a>.</p>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}`}>
                        <button className="btn btn-outline-primary">Go to Products</button>
                      </Link>                    
                    </div>
                  </div>
              </div>
            </Card>
          </Block>
        }
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default ProductDetails;