import React, { useState, useEffect } from "react";
import { Empty, Switch, Select, Popover } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import moment from 'moment';
import { getAxiosHeaders, getQueryParams, findUpper, getTeamMemberRoleByName } from "../../../utils/Utils";
import { Badge, 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  UserAvatar,
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal,
  InfoModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";

export const TeamMembersTable = ({ history, businessID, currency, ...props }) => {
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0,
    }, 
    team_members: []
  });
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
   const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    member_name: '',
    email: '',
    status: 'all'
  });
  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const selectAll = () => {
    setCheckAll(prevCheckAll => {
      if (prevCheckAll) {
        setCheckedBoxes([]);
      } else {
        const allIDs = tableData.team_members.map(item => item.member_id);
        setCheckedBoxes(allIDs);
      }
      return !prevCheckAll;
    });
  }

  const handleCheckboxChange = (value) => {
    setCheckedBoxes(prevBoxes => {
      if (prevBoxes.includes(value)) {
          return prevBoxes.filter(id => id !== value);
      } else {
          return [...prevBoxes, value];
      }
    });
    if (checkAll) {
        setCheckAll(false);
    }
  };

  const currentUrl = (filters) => {
    let member_name = filters.member_name.length > 0 ? `&ms=${filters.member_name}` : ``;
    let email_search = filters.email.length > 0 ? `&e=${filters.email}` : ``;
    let status_search = filters.status.length > 0 ? `&s=${filters.status}` : ``;
    
    if( (member_name !== '' ||
    email_search !== '' || 
    filters.status !== 'all') && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${member_name}${email_search}${status_search}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getTeamMembers(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      member_name: '',
      email: '',
      status: 'all'
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getTeamMembers(params);
  }

  const filterTeamMembers = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getTeamMembers(filters);
  }

  const getTeamMembers = (filters) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + "/teams", {
      params: {
        business_id: businessID,
        page: filters.page,
        member_name: filters.member_name,
        email: filters.email,
        status: filters.status
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
          }, 
          team_members:[]
        });
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
          }, 
          team_members:[]
        });

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const sendInvitation = () => {
    setErrorMessage("");
    toggleModal('loadingInviteModal');

    axios.post(baseURLs.API_URL + `/teams/resend-invitation/${checkedBoxes[0]}`, {
      business_id: businessID
    }, getAxiosHeaders())
    .then((response) => {

      toggleModal('successInviteModal');

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('inviteAlertModal');
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
        toggleModal('inviteAlertModal');
      }
    });
  }

  const deleteTeamMembersByIDs = () => {
    toggleModal('deleteLoadingModal');

    let _checkedBoxes = checkedBoxes;
    if(_checkedBoxes.length > 0){
      _checkedBoxes = _checkedBoxes.map((id) => parseInt(id));
    }

    axios.delete(baseURLs.API_URL + "/teams/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        members: JSON.stringify(_checkedBoxes),
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_ids;
      let failedIDs = responseInfo.data.failed_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getTeamMembers(filters);
        toggleModal('successDeleteModal');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getTeamMembers(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `members` : `member`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `members` : `member`} failed to get deleted`);
        toggleModal('deleteErrorModal');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `members` : `member`}. The selected ${failedIDs.length > 1 ? `members were` : `member was`} not found.`);
        toggleModal('deleteErrorModal');
      }

      setCheckedBoxes([]);
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteTeamMembersByFilter = () => {
    toggleModal('deleteLoadingModal');

    axios.delete(baseURLs.API_URL + "/teams/by-filters", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        member_name: filters.member_name,
        email: filters.email,
        status: filters.status
      }
    })
    .then((response) => {
      getTeamMembers(filters);
      toggleModal('successDeleteModal');
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleSelectedRecordsAction = (action) => {
    if(!checkAll && checkedBoxes.length === 0){
      toggleModal('noSelectionAlertModal');
      return;
    }

    toggleModal('deleteAlertModal');    
  }

  const handleSingleDelete = (memberID) => {
    setCheckedBoxes([memberID]);

    setTimeout(() => {
      toggleModal('deleteAlertModal');       
    }, 500);
  }

  const handleResendInvite = (memberID) => {
    setCheckedBoxes([memberID]);

    setTimeout(() => {
      toggleModal('inviteAlertModal');       
    }, 500);
  }

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let member_name = queryParams.hasOwnProperty('ms') ? queryParams.ms : '';
    let email = queryParams.hasOwnProperty('e') ? queryParams.e : '';
    let status_search = queryParams.hasOwnProperty('st') ? queryParams.st : 'all';

    let params = filters;
    params.page = page;
    params.member_name = member_name;
    params.email = email;
    params.status = status_search;

    setFilters(params);
    getTeamMembers(params);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Members` : `Member`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Team Members Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="member_name">
                                    Member's Name
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="member_name" className="form-control form-control-lg" id="member_name" defaultValue={filters.member_name} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="email">
                                    Email
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="email" className="form-control form-control-lg" id="email" defaultValue={filters.email} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="phone_search">
                                    Status
                                  </label>
                                  <div className="form-control-wrap">
                                    <Select size="large"                          
                                      placeholder="Select status"
                                      defaultValue={filters.status}
                                      style={{ width: "100%" }} 
                                      name="status"
                                      onChange={(value) => onSelectChange(value, "status")}
                                      filterOption={(input, option) =>
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      options={[
                                        {value: "all", label: "All"},
                                        {value: "pending", label: "Pending"},
                                        {value: "active", label: "Active"},
                                      ]} 
                                      showSearch /> 
                                  </div>
                                </div>
                                <div className="form-group mt-5">
                                  <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterTeamMembers();} } size="md">
                                    Apply Filter
                                  </Button>

                                  {
                                    hasFilters &&
                                    <Button className="ms-3 text-muted" color="lighter" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="md">
                                      Reset Filter
                                    </Button>
                                  }
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-light justify-content-start">
                              <span className="sub-text">Use the form above to filter your team members.</span>
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getTeamMembers(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="p-0">
                {
                  tableData.team_members.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No members found</h5><span>Add a team member using the button below.</span>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/team/b/${businessID}/add`}>
                        <button className="btn btn-outline-primary">Add a Team Member</button>
                      </Link>                    
                    </div>
                  </div>
                  :
                  <>      
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input
                              type="checkbox"
                              checked={checkAll}
                              onChange={selectAll}
                              className="custom-control-input table-checkbox"
                              id="select_all" />
                            <label className="custom-control-label" htmlFor="select_all"/>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text">Name</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Email</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Role</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Status</span>
                        </DataTableRow>
                        <DataTableRow size="xl">
                          <span className="sub-text">Date</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt text-capitalize" style={{letterSpacing: "normal"}}>
                                <li>
                                  <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSelectedRecordsAction('cancel')}>
                                    <Icon name="trash"></Icon>
                                    <span>Delete Selected Members</span>
                                    {
                                      activeModal === "deleteAlertModal" &&
                                      <AlertModal showModal={true} toggleModal={() => toggleModal('deleteAlertModal')}
                                        headerText={checkedBoxes.length > 1 ? `Delete Members` : `Delete Member`} 
                                        descriptionText={`${checkAll ? 
                                        `Are you sure you want to delete all ${tableData.meta.total_records} members. This action can not be reverted.` 
                                        :
                                        `Are you sure you want to delete ${checkedBoxes.length > 1 ? `these ${checkedBoxes.length} selected members` : `this selected member`}. This action can not be reverted.`}`}
                                        leftButtonText={`Delete`}
                                        leftButtonOnClick={checkAll ? deleteTeamMembersByFilter : deleteTeamMembersByIDs} />
                                    }
                                  </DropdownItem>
                                </li>
                              </ul>
                              {
                                activeModal === "noSelectionAlertModal" &&
                                <AlertModal showModal={true} toggleModal={() => toggleModal('noSelectionAlertModal')}
                                  headerText={`Error`} 
                                  descriptionText={`No member has been selected. Select a member and try again.`}
                                />
                              }

                              { activeModal === "deleteLoadingModal" && <LoadingModal showModal={true} headerText={`Deleting...`} descriptionText={`Please wait while the ${checkedBoxes.length > 1 ? `members are` : `member is`} being deleted`} /> }
                        
                              { 
                                activeModal === "successDeleteModal" &&
                                <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
                                  headerText={`Deleted`} descriptionText={`Member(s) deleted successfully.`} 
                                />
                              }
                              
                              {
                                activeModal === "deleteErrorModal" &&
                                <AlertModal showModal={true} toggleModal={() => toggleModal(null)}
                                  headerText={"Error"} descriptionText={errorMessage}                       
                                />
                              }
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.team_members.map((data, index) => {
                          let memberStatus = data.status === 'active' ? <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="success">Active</Badge></div> :
                          <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="warning">Pending</Badge></div>;                            
                          let role = getTeamMemberRoleByName(data.role).role;
                          
                          return (
                            <DataTableItem key={index}>
                              <DataTableRow className="nk-tb-col-check">
                                <div className="custom-control custom-control-sm custom-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={checkAll || checkedBoxes.includes(data.member_id)}
                                    onChange={() => handleCheckboxChange(data.member_id)}
                                    className="custom-control-input table-checkbox"
                                    id={`item${data.member_id}`} 
                                  />
                                  <label className="custom-control-label" htmlFor={`item${data.member_id}`} />
                                </div>
                              </DataTableRow>
                              <DataTableRow>
                                <div className="user-card">
                                  <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                                  {
                                    data.full_name &&
                                    <UserAvatar className="sq md" theme="lighter" text={findUpper(data.full_name)} />
                                  }
                                  <div className="user-info">
                                    <span className={`tb-lead fw-normal`}>
                                      {data.full_name}{" "}
                                    </span>
                                    <div className="price d-md-none">{data.email}</div>
                                    <div className="price d-md-none">{role} • {memberStatus}</div>
                                  </div>
                                </div>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{data.email}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{role}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                {memberStatus}
                              </DataTableRow>
                              <DataTableRow size="xl">
                                <span>{data.updated_at && moment(data.updated_at).format("Do MMM YYYY")}</span>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/team/b/${businessID}/permissions/${data.member_id}`}>                                            
                                          <Icon name="user-list"></Icon>
                                          <span>Update Permissions</span>
                                        </Link>
                                      </li>
                                      
                                      {
                                        data.status === 'pending' ?
                                        <li>
                                          <DropdownItem tag="a" className="pointer-cursor" onClick={() => handleResendInvite(data.member_id)}>
                                            <Icon name="send"></Icon>
                                            <span>Resend Invitation</span>
                                          </DropdownItem>
                                        </li>
                                        :
                                        <li>
                                          <Link to={`${process.env.PUBLIC_URL}/team/b/${businessID}/logs?e=${data.email}`}>
                                            <Icon name="list-round"></Icon>
                                            <span>View Logs</span>
                                          </Link>
                                        </li>
                                      }
                                      <li className="divider"></li>
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSingleDelete(data.member_id)}>
                                          <Icon name="trash"></Icon>
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                  </>
                }              
              </div>
            </div>
          </Card>

          {
            activeModal === "loadingInviteModal" &&
            <LoadingModal 
              showModal={true}
              toggleModal={() => toggleModal('loadingInviteModal')}
              headerText={"Sending..."}
              descriptionText={"Please wait while we send an invitation to your team member..."} />
          }

          { 
            activeModal === "successInviteModal" &&
            <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
              headerText={`Sent`} descriptionText={`Invitation has been sent to your team member successfully.`} 
            />
          }

          {
            activeModal === "inviteAlertModal" &&
            <InfoModal 
              index="invite"
              showModal={true} 
              toggleModal={() => toggleModal('inviteAlertModal')}
              headerText={`Resend Invitation?`}
              descriptionText={`Will you like to resend the invitation email to this team member?`}
              leftButtonText={`Send Invitation`}
              leftButtonOnClick={sendInvitation}
              rightButtonText={`Close`}
              rightButtonOnClick={() => toggleModal('inviteAlertModal')} >
              {
                errorMessage.length > 0 && <p className="text-danger mt-4">{errorMessage}</p>
              }
            </InfoModal>
          }
        </>
      }
    </Block>
  );
};
