import React, { useState, useEffect } from "react";
import { Empty, Select } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { getAxiosHeaders, getQueryParams } from "../../../utils/Utils";
import { Badge, 
  DropdownToggle, 
  DropdownMenu, 
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";

export const PurchaseOrdersTable = ({ history, businessID, currency, ...props }) => {
  const [tableData, setTableData] = useState({meta: {total_records: 0}, orders:[]});
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [dateFrom, dateTo] = dateRange;
  const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    order_search: '',
    supplier_search: '',
    range: null,
    rangeStart: null,
    rangeEnd: null,
    status: 'all',
    order_status: 'all',
    order_type: 'all'
  });
  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onRangeChange = (dates) => {
    const [start, end] = dates;
    let params = filters;

    if(!moment(start).isValid() || !moment(end).isValid()){
      params.range = null;
      params.rangeStart = null;
      params.rangeEnd = null;
      setFilters({...params});
      return;
    }

    params.range = `${moment(start).format("YYYY-MM-DD")},${moment(end).format("YYYY-MM-DD")}`;
    params.rangeStart = start;
    params.rangeEnd = end;
    setFilters({...params});
  };

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const selectAll = () => {
    setCheckAll(prevCheckAll => {
      if (prevCheckAll) {
        setCheckedBoxes([]);
      } else {
        const allIDs = tableData.orders.map(item => item.order_id);
        setCheckedBoxes(allIDs);
      }
      return !prevCheckAll;
    });
  }

  const handleCheckboxChange = (value) => {
    setCheckedBoxes(prevBoxes => {
      if (prevBoxes.includes(value)) {
          return prevBoxes.filter(id => id !== value);
      } else {
          return [...prevBoxes, value];
      }
    });
    if (checkAll) {
        setCheckAll(false);
    }
  };

  const currentUrl = (filters) => {
    let order_search = filters.order_search.length > 0 ? `&o=${filters.order_search}` : ``;
    let supplier_search = filters.supplier_search.length > 0 ? `&sp=${filters.supplier_search}` : ``;
    let status = filters.status !== null ? `&s=${filters.status}` : ``;
    let order_status = filters.order_status !== null ? `&os=${filters.order_status}` : ``;
    let order_type = filters.order_type !== null ? `&ot=${filters.order_type}` : ``;
    let range = filters.range !== null ? `&r=${filters.range}` : ``;
    
    if( (order_search !== '' || supplier_search !== '' ||
    filters.order_status !== 'all' || filters.order_status !== 'all' || 
    range !== '' || filters.status !== 'all') && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${order_search}${supplier_search}${range}${order_status}${order_type}${status}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getOrders(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      order_search: '',
      supplier_search: '',
      range: null,
      rangeStart: null,
      rangeEnd: null,
      status: 'all',
      order_status: 'all',
      order_type: 'all'
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getOrders(params);
  }

  const filterOrders = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getOrders(filters);
  }

  const getOrders = (filters) => {
    setLoading(true);

    axios.get(baseURLs.API_URL + "/purchase-orders", {
      params: {
        business_id: businessID,
        page: filters.page,
        order_number: filters.order_search,
        supplier: filters.supplier_search,
        date_from: !moment(filters.rangeStart).isValid() ? null : moment(filters.rangeStart).format("YYYY-MM-DD"),
        date_to: !moment(filters.rangeEnd).isValid() ? null : moment(filters.rangeEnd).format("YYYY-MM-DD"),
        order_status: filters.order_status,
        order_type: filters.order_type,
        status: filters.status
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({meta: {total_records: 0}, orders:[]});
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({meta: {total_records: 0}, orders:[]});

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteOrdersByIDs = () => {
    toggleModal('deleteLoadingModal');

    axios.delete(baseURLs.API_URL + "/purchase-orders/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        orders: JSON.stringify(checkedBoxes),
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_order_ids;
      let failedIDs = responseInfo.data.failed_order_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getOrders(filters);
        toggleModal('successDeleteModal');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getOrders(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `purchase orders` : `purchase order`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `purchase orders` : `purchase order`} failed to get deleted`);
        toggleModal('deleteErrorModal');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `purchase orders` : `purchase order`}. The selected ${failedIDs.length > 1 ? `purchase orders were` : `purchase order was`} not found.`);
        toggleModal('deleteErrorModal');
      }

      setCheckedBoxes([]);
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteOrdersByFilter = () => {
    toggleModal('deleteLoadingModal');

    axios.delete(baseURLs.API_URL + "/purchase-orders/by-filters", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        order_number: filters.order_search,
        supplier: filters.supplier_search,
        date_from: !moment(filters.rangeStart).isValid() ? null : moment(filters.rangeStart).format("YYYY-MM-DD"),
        date_to: !moment(filters.rangeEnd).isValid() ? null : moment(filters.rangeEnd).format("YYYY-MM-DD"),
        order_status: filters.order_status,
        order_type: filters.order_type,
        status: filters.status
      }
    })
    .then((response) => {
      getOrders(filters);
      toggleModal('successDeleteModal');
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleSelectedRecordsAction = (action) => {
    if(!checkAll && checkedBoxes.length === 0){
      toggleModal('noSelectionAlertModal');
      return;
    }

    toggleModal('deleteAlertModal');    
  }

  const handleSingleDelete = (orderID) => {
    setCheckedBoxes([orderID]);

    setTimeout(() => {
      toggleModal('deleteAlertModal');       
    }, 500);
  }

  const downloadOrders = () => {
    setErrorMessage("");
    toggleModal('loadingModal');
    
    axios.get(baseURLs.API_URL + "/purchase-orders/download", {
      params: {
        business_id: businessID,
        order_number: filters.order_search,
        supplier: filters.supplier_search,
        date_from: !moment(filters.rangeStart).isValid() ? null : moment(filters.rangeStart).format("YYYY-MM-DD"),
        date_to: !moment(filters.rangeEnd).isValid() ? null : moment(filters.rangeEnd).format("YYYY-MM-DD"),
        order_status: filters.order_status,
        order_type: filters.order_type,
        status: filters.status
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('No order found');        
        toggleModal('alertModal');
      } else {
        let responseInfo = response.data;
        setDownloadLink(responseInfo.data.download_link);
        toggleModal('downloadModal');
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  useEffect(() => {
    onRangeChange(dateRange);
  }, [dateRange])

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let order_search = queryParams.hasOwnProperty('o') ? queryParams.o : '';
    let supplier_search = queryParams.hasOwnProperty('sp') ? queryParams.sp : '';
    let range = queryParams.hasOwnProperty('r') ? queryParams.r : null;
    let order_status = queryParams.hasOwnProperty('os') ? queryParams.os : 'all';
    let order_type = queryParams.hasOwnProperty('ot') ? queryParams.ot : 'all';
    let status = queryParams.hasOwnProperty('s') ? queryParams.s : 'all';
    let rangeStart = null;
    let rangeEnd = null;

    if(range !== null){
      let rangeSplit = range.split(',');
      rangeStart = new Date(rangeSplit[0]);
      rangeEnd = new Date(rangeSplit[1]);
      setDateRange([rangeStart, rangeEnd]);
    }

    let params = filters;
    params.page = page;
    params.order_search = order_search;
    params.supplier_search = supplier_search;
    params.range = range;
    params.rangeStart = rangeStart;
    params.rangeEnd = rangeEnd;
    params.status = status;
    params.order_status = order_status;
    params.order_type = order_type;

    setFilters(params)
    getOrders(params);
  }, []);

  
  return (
    <Block>
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          {
            tableData.orders.length > 0 &&
            <div className="row g-gs mb-5">
              <div className="col-md-12">
                <Card className="card-bordered">
                  <div className="card-inner">
                    <div className="analytic-data-group analytic-ov-group g-3 justify-content-md-evenly ">
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-success">Amount Paid</div>
                        <div className="amount"><small className="text-muted fw-light">{currency}</small> {tableData.meta.amount_paid}</div>
                      </div>
                      <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-danger">Amount Owed</div>
                        <div className="amount"><small className="text-muted fw-light">{currency}</small> {tableData.meta.amount_owed}</div>
                      </div>
                    </div>
                  </div>
                </Card>   
              </div>
            </div>
          }
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Orders` : `Order`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Purchase Order Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="order_search">
                                    Order Number
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="order_search" className="form-control form-control-lg" id="order_search" defaultValue={filters.order_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="supplier_search">
                                    Supplier Name
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="supplier_search" className="form-control form-control-lg" id="supplier_search" defaultValue={filters.supplier_search} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="status">
                                    Payment Status
                                  </label>
                                  <div className="form-control-wrap">
                                  <Select size="large"                          
                                      placeholder="Select status"
                                      defaultValue={filters.status}
                                      style={{ width: "100%" }} 
                                      name="status"
                                      onChange={(value) => onSelectChange(value, "status")}
                                      filterOption={(input, option) =>
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      options={[
                                        {value: "all", label: "All"},
                                        {value: "paid", label: "Paid Orders"},
                                        {value: "owed", label: "Owed Orders"},
                                      ]} 
                                      showSearch /> 
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="order_status">
                                    Order Status
                                  </label>
                                  <div className="form-control-wrap">
                                  <Select size="large"                          
                                      placeholder="Select order status"
                                      defaultValue={filters.order_status}
                                      style={{ width: "100%" }} 
                                      name="order_status"
                                      onChange={(value) => onSelectChange(value, "order_status")}
                                      filterOption={(input, option) =>
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      options={[
                                        {value: "all", label: "All"},
                                        {value: "reviewing", label: "Reviewing"},
                                        {value: "pending_approval", label: "Pending Approval"},
                                        {value: "approved", label: "Approved"},
                                        {value: "pending_delivery", label: "Pending Delivery"},
                                        {value: "delivered", label: "Delivered"},
                                      ]} 
                                      showSearch /> 
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="order_status">
                                    Order Type
                                  </label>
                                  <div className="form-control-wrap">
                                  <Select size="large"                          
                                      placeholder="Select order status"
                                      defaultValue={filters.order_type}
                                      style={{ width: "100%" }} 
                                      name="order_type"
                                      onChange={(value) => onSelectChange(value, "order_type")}
                                      filterOption={(input, option) =>
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      options={[
                                        {value: "all", label: "All"},
                                        {value: "inventory", label: "Inventory"},
                                        {value: "raw_materials", label: "Raw Materials"}
                                      ]} 
                                      showSearch /> 
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label">Date Range</label>                          
                                  <div className="form-control-wrap">
                                    <DatePicker
                                      selectsRange={true}
                                      startDate={dateFrom}
                                      endDate={dateTo}
                                      onChange={(date) => {
                                        setDateRange(date);
                                      }}
                                      dateFormat="dd/MM/yyyy"
                                      showMonthDropdown
                                      showYearDropdown
                                      isClearable
                                      // monthsShown={2}
                                      className="form-control form-control-lg date-picker"
                                    />
                                  </div>
                                  <div className="form-note fs-10px">
                                    Date Format: <code>dd/mm/yyyy</code>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterOrders();} } size="md">
                                    Apply Filter
                                  </Button>

                                  {
                                    hasFilters &&
                                    <Button className="ms-3 text-muted" color="lighter" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="md">
                                      Reset Filter
                                    </Button>
                                  }
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-light justify-content-start">
                              <span className="sub-text">Use the form above to filter your purchase orders.</span>
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getOrders(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={downloadOrders} title="Download Purchase Orders">
                          <Icon name="download-cloud"></Icon>
                        </Button>
                        { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={"Preparing Purchase Orders"} descriptionText={"Please wait while your purchase orders are being prepared for download."} /> }
                        
                        { 
                          activeModal === "downloadModal" &&
                          <SuccessModal showModal={true} toggleModal={() => toggleModal('downloadModal')}
                            headerText={"Purchase Orders Ready"} descriptionText={"Purchase Orders CSV is ready for download."} 
                            leftButtonText={"Download"} leftButtonOnClick={() => {window.open(downloadLink, '_blank').focus(); toggleModal('downloadModal');}}
                          />
                        }
                        
                        {
                          activeModal === "alertModal" &&
                          <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
                            headerText={"Download Failed"} descriptionText={errorMessage}                       
                          />
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="card-inner p-0">
                {
                  tableData.orders.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No order found</h5><span>Create a purchase order using the button below.</span>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/purchase-orders/b/${businessID}/add`}>
                        <button className="btn btn-outline-primary">Create a Purchase Order</button>
                      </Link>                    
                    </div>
                  </div>
                  :
                  <>      
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input
                              type="checkbox"
                              checked={checkAll}
                              onChange={selectAll}
                              className="custom-control-input table-checkbox"
                              id="select_all" />
                            <label className="custom-control-label" htmlFor="select_all"/>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text">#</span>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text">Order Type</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Issued By</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Total</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Status</span>
                        </DataTableRow>
                        <DataTableRow size="xl">
                          <span className="sub-text">Date</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt text-capitalize" style={{letterSpacing: "normal"}}>
                                <li>
                                  <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSelectedRecordsAction('cancel')}>
                                    <Icon name="trash"></Icon>
                                    <span>Delete Selected Purchase Orders</span>
                                    {
                                      activeModal === "deleteAlertModal" &&
                                      <AlertModal showModal={true} toggleModal={() => toggleModal('deleteAlertModal')}
                                        headerText={checkedBoxes.length > 1 ? `Delete Purchase Orders` : `Delete Purchase Order`} 
                                        descriptionText={`${checkAll ? 
                                        `Are you sure you want to delete all ${tableData.meta.total_records} purchase orders. This action can not be reverted.` 
                                        :
                                        `Are you sure you want to delete ${checkedBoxes.length > 1 ? `these ${checkedBoxes.length} selected purchase orders` : `this selected purchase order`}. This action can not be reverted.`}`}
                                        leftButtonText={`Delete`}
                                        leftButtonOnClick={checkAll ? deleteOrdersByFilter : deleteOrdersByIDs} />
                                    }
                                  </DropdownItem>
                                </li>
                              </ul>
                              {
                                activeModal === "noSelectionAlertModal" &&
                                <AlertModal showModal={true} toggleModal={() => toggleModal('noSelectionAlertModal')}
                                  headerText={`Error`} 
                                  descriptionText={`No purchase order has been selected. Select an order and try again.`}
                                />
                              }

                              { activeModal === "deleteLoadingModal" && <LoadingModal showModal={true} headerText={`Deleting...`} descriptionText={`Please wait while the ${checkedBoxes.length > 1 ? `purchase orders are` : `purchase order is`} being deleted`} /> }
                        
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.orders.map((data, index) => {
                          return (
                            <DataTableItem key={index}>
                              <DataTableRow className="nk-tb-col-check">
                                <div className="custom-control custom-control-sm custom-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={checkAll || checkedBoxes.includes(data.order_id)}
                                    onChange={() => handleCheckboxChange(data.order_id)}
                                    className="custom-control-input table-checkbox"
                                    id={`item${data.order_id}`} 
                                  />
                                  <label className="custom-control-label" htmlFor={`item${data.order_id}`} />
                                </div>
                              </DataTableRow>
                              <DataTableRow>
                                <Link to={`${process.env.PUBLIC_URL}/purchase-orders/b/${businessID}/details/${data.order_id}`}>
                                  <div className="user-card">
                                    {/* <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span> */}
                                    <div className="user-info">
                                      <div className="text-bold text-primary">#{data.order_number}</div>
                                      { 
                                        data.has_supplier === 'yes' &&
                                        <small className="text-muted" title="supplier"><em className="icon ni ni-user-alt me-1"></em>{data.supplier_name}</small>
                                      }
                                      <div className={`price d-md-none`}>
                                        <span>
                                          {
                                            data.status === 'paid' ?
                                            <span className="tb-amount">
                                              <small className="text-muted">{currency} </small>{data.amount_paid}
                                            </span>
                                            :
                                            <span className="tb-amount">
                                              <small className="text-muted">{currency} </small>{data.amount_paid}
                                              <small className="text-muted fw-bold"> / {data.total_amount}</small>
                                            </span>
                                          }
                                        </span>
                                        <span>
                                        {
                                          data.status === 'paid' ?
                                          <Badge className="badge-dot me-2" color="success">Paid</Badge>
                                          : 
                                          <Badge className="badge-dot" color="danger">Owed</Badge>
                                        }
                                        {
                                          data.order_status === 'reviewing' &&
                                          <Badge className="badge-dot me-2" color="gray">Reviewing</Badge>
                                        
                                        }

                                        {
                                          data.order_status === 'pending_approval' &&
                                          <Badge className="badge-dot me-2" color="warning">Pending Approval</Badge>
                                        }

                                        {
                                          data.order_status === 'approved' &&
                                          <Badge className="badge-dot me-2" color="warning">Approved</Badge>
                                        }
                                        
                                        {
                                          data.order_status === 'pending_delivery' &&
                                          <Badge className="badge-dot me-2" color="warning">Pending Delivery</Badge>
                                        }

                                        {
                                          data.order_status === 'delivered' &&
                                          <Badge className="badge-dot me-2" color="success">Delivered</Badge>
                                        }
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </DataTableRow>
                              <DataTableRow>
                                <span>{data.order_type === 'inventory' ? 'Inventory' : 'Raw Materials'}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{data.issued_by}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                {
                                  data.status === 'paid' ?
                                  <span className="tb-amount">
                                    <small className="text-muted">{currency} </small>{data.amount_paid}
                                  </span>
                                  :
                                  <span className="tb-amount">
                                    <small className="text-muted">{currency} </small>{data.amount_paid}
                                    <small className="text-muted fw-bold"> / {data.total_amount}</small>
                                  </span>
                                }
                              </DataTableRow>
                              <DataTableRow size="md" className={"text-start"}>
                                {
                                  data.status === 'paid' ?
                                  <div className="tb-tnx-status">
                                  <Badge className="badge-dot" color="success">Paid</Badge>
                                  </div> 
                                  : 
                                  <div className="tb-tnx-status">
                                    <Badge className="badge-dot" color="danger">Owed</Badge>
                                  </div>
                                }
                                <br></br>
                                {
                                  data.order_status === 'reviewing' &&
                                  <div className="tb-tnx-status">
                                    <Badge className="badge-dot" color="gray">Reviewing</Badge>
                                  </div> 
                                
                                }

                                {
                                  data.order_status === 'pending_approval' &&
                                  <div className="tb-tnx-status">
                                    <Badge className="badge-dot" color="warning">Pending Approval</Badge>
                                  </div> 
                                
                                }
                                
                                {
                                  data.order_status === 'approved' &&
                                  <div className="tb-tnx-status">
                                    <Badge className="badge-dot" color="warning">Approved</Badge>
                                  </div>
                                }
                                
                                {
                                  data.order_status === 'pending_delivery' &&
                                  <div className="tb-tnx-status">
                                    <Badge className="badge-dot" color="warning">Pending Delivery</Badge>
                                  </div>
                                }

                                {
                                  data.order_status === 'delivered' &&
                                  <div className="tb-tnx-status">
                                    <Badge className="badge-dot" color="success">Delivered</Badge>
                                  </div>
                                }
                              </DataTableRow>
                              <DataTableRow size="xl">
                                <span>{moment(data.order_date).format("Do MMM YYYY")}</span>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/purchase-orders/b/${businessID}/details/${data.order_id}`}>                                            
                                          <Icon name="eye"></Icon>
                                          <span>View</span>
                                        </Link>
                                      </li>
                                      {
                                        data.order_status !== 'delivered' &&
                                        <li>
                                          <Link to={`${process.env.PUBLIC_URL}/purchase-orders/b/${businessID}/edit/${data.order_id}`}>
                                            <Icon name="edit-alt"></Icon>
                                            <span>Edit</span>
                                          </Link>
                                        </li>
                                      }
                                      <li className="divider"></li>
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSingleDelete(data.order_id)}>
                                          <Icon name="trash"></Icon>
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                  </>
                }              
              </div>
            </div>
          </Card>
        </>
      }

      { 
        activeModal === "successDeleteModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={`Deleted`} descriptionText={`Purchase Order(s) deleted successfully.`} 
        />
      }
      
      {
        activeModal === "deleteErrorModal" &&
        <AlertModal showModal={true} toggleModal={() => toggleModal(null)}
          headerText={"Error"} descriptionText={errorMessage}                       
        />
      }
    </Block>
  );
};
