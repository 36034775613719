import React, { useEffect, useState } from "react";
import { getAxiosHeaders } from "../../../utils/Utils";
import {
  Icon,
  Button
} from "../../../components/Component";
import { baseURLs } from '../../../utils/Constants';
import { Spinner } from "reactstrap";
import axios from 'axios';
import moment from "moment";
import DatePicker from "react-datepicker";
import { Input, Select, Checkbox, Form, InputNumber } from 'antd';
import { AlertModal, SuccessModal } from "../AlertModals";

const BalancePaymentForm = ({ saleInfo, setSaleInfo, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [checked, setChecked] = useState(true);
  const [formData, setFormData] = useState({
    date: new Date(),
    time: new Date(),
    payment_type: "cash",
    amount_paid: "",
    alert_customer: true
  })
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  let paymentTypeOptions = [
    {value: 'cash', label: 'Cash'},
    {value: 'check', label: 'Check'},
    {value: 'card payment', label: 'Card Payment'},
    {value: 'bank', label: 'Bank Transfer / Deposit'},
    {value: 'mobile money', label: 'Mobile Money'},
    {value: 'digital wallet', label: 'Digital Wallet'},
  ]

  const handleFormSubmit = (_formData) => {
    setLoading(true);
    setErrorMessage("");

    axios.put(baseURLs.API_URL + `/sales/balance/${saleInfo.sale_id}`, {
      business_id: saleInfo.business_id,
      amount_paid: _formData.amount_paid,
      payment_type: _formData.payment_type,
      date: `${moment(_formData.date).format("YYYY-MM-DD")} ${moment(_formData.time).format("HH:mm")}`,
      alert_customer: _formData.alert_customer ? "yes" : "no",
    }, getAxiosHeaders())
    .then((response) => {
      let responseInfo = response.data;

      let saleData = saleInfo;
      saleData.balance_due = responseInfo.data.balance_due;
      saleData.amount_paid = responseInfo.data.amount_paid;
      saleData.amount_received = responseInfo.data.amount_received;
      saleData.change = responseInfo.data.change;
      saleData.status = responseInfo.data.status;
      saleData.outstanding_balance = responseInfo.data.outstanding_balance;
      saleData.payment_log = responseInfo.data.payment_log.reverse();
      
      setSaleInfo({...saleData});
      toggleModal('successModal');
      form.resetFields();
      setLoading(false);
    }).catch((error) => {
      console.log(error)
      try{
        let errorResponse = error.response.data;

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
      }catch(e){
        setErrorMessage("Error: Could not connect to server");
      }

      toggleModal('errorModal');
      setLoading(false);
    });
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  
  return (
    <div className="col-md-12">
      <hr />
      <p className="preview-title overline-title fs-12px">Update Balance</p>
      {
        errorMessage && <p className="text-danger"> <em className="icon ni ni-alert-circle"></em> {errorMessage}</p>
      }
      <Form name="update-sale-balance"
        form={form} className="is-alter"
        initialValues={formData} 
        onFinish={handleFormSubmit}
        onFinishFailed={onFinishFailed}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <div className="form-label-group">
                  <label className="form-label">Date <span className="text-danger">*</span></label>
              </div>
              <div className="row g-2">
                <div className="col-7">
                  <Form.Item>
                    <Form.Item name={'date'}
                      noStyle
                      rules={[
                        {required: true, message: 'Date is required',}
                      ]}>
                      <DatePicker
                        selected={selectedDate}
                        dateFormat="dd-MMM-yyyy"
                        onChange={date => {
                          // date = moment(date).format("YYYY-MM-dd");
                          setSelectedDate(date);
                          form.setFieldValue({date}); // Update Ant Design Form
                        }}
                        shouldCloseOnSelect={false}
                        showMonthDropdown
                        showYearDropdown
                        isClearable
                        className="form-control form-control-lg date-picker"
                      />
                    </Form.Item>
                  </Form.Item>
                </div>
                <div className="col-5">
                  <Form.Item>
                    <Form.Item name={'time'}
                      noStyle
                      rules={[
                        {required: true, message: 'time is required',}
                      ]}>
                      <DatePicker
                        selected={selectedTime}
                        dateFormat="h:mm aa"
                        onChange={time => {
                          // time = moment(time).format("HH:mm A");
                          setSelectedTime(time);
                          form.setFieldValue({time}); // Update Ant Design Form
                        }}
                        shouldCloseOnSelect={false}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        className="form-control form-control-lg date-picker"
                      />
                    </Form.Item>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <Form.Item>
                <div className="form-label-group">
                  <label className="form-label">Payment Type <span className="text-danger">*</span></label>
                </div>
                <div className="form-control-wrap">
                <Form.Item name={'payment_type'}
                  noStyle
                  rules={[
                    {required: true, message: 'Payment type is required',}
                  ]}>
                  <Select required size="large" 
                    name="country"
                    placeholder="Select payment type"
                    style={{ width: "100%" }}
                    options={paymentTypeOptions} 
                    showSearch />   
                  </Form.Item>                       
                </div>
              </Form.Item>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <Form.Item>
                <div className="form-label-group">
                  <label className="form-label">Amount Paid <span className="text-danger">*</span></label>
                </div>
                <div className="form-control-wrap">
                <Form.Item name={'amount_paid'}
                  noStyle
                  rules={[
                    {required: true, message: 'Amount paid is required',}
                  ]}>
                    <InputNumber prefix={saleInfo.currency} min={0.00} step={0.01} size="large" className="w-100" />
                  </Form.Item>                       
                </div>
              </Form.Item>
            </div>
          </div>
          <div className="col-md-6">
            <Form.Item name="alert_customer" valuePropName="checked">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  checked={checked}
                  onChange={() => {
                    setChecked(!checked);
                    form.setFieldValue(!checked); // Update Ant Design Form
                  }}
                  id="alert_customer" />
                <label className="custom-control-label" htmlFor="alert_customer">
                  Alert Customer
                </label>
              </div>
            </Form.Item>
          </div>
          <div className="col-md-12">
            <div className="text-end">
              <Button disabled={loading} type="submit" color="primary" size="md" >
                {loading ? <Spinner size="sm" color="light" /> : "Submit"}
              </Button>
            </div>
          </div>
        </div>
      </Form>

      {
        activeModal === "successModal" &&
        <SuccessModal showModal={true} toggleModal={() => toggleModal('successModal')}
          headerText={"Balance Updated"} descriptionText={"The balance for this sale has been updated successfully."} />
      }

      {
        activeModal === "errorModal" &&
        <AlertModal showModal={true} toggleModal={() => toggleModal('errorModal')}
          headerText={"Balance Update Failed"} descriptionText={errorMessage}                       
        />
      }
    </div>
  );
};


export default BalancePaymentForm;