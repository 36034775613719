import React, { useState, useEffect } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  Spinner, 
  Alert, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter  } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
} from "../components/Component";
import { Select } from 'antd';
import { baseURLs } from '../utils/Constants';
import { getAxiosHeaders } from "../utils/Utils";
import { Link } from "react-router-dom";
import classNames from "classnames";
import axios from 'axios';
import DatePicker from "react-datepicker";
import moment from "moment";

const ProfitAndLoss = ({ history, match }) => {
  const [sm, updateSm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingReport, setLoadingReport] = useState(true);
  const [businessID, setBusinessID] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [incomeStatementReport, setIncomeStatementReport] = useState([]);
  const [expensesCategory, setExpensesCategory] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [accountingType, setAccountingType] = useState("cash_accounting");
  const [periodType, setPeriodType] = useState("month");
  const [periodDate1, setPeriodDate1] = useState(new Date());
  const [periodDate2, setPeriodDate2] = useState(null);
  const [periodDate3, setPeriodDate3] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const getIncomeStatement = (businessID) => {
    setErrorMessage("");

    if(!periodDate1) {
      setErrorMessage("Period Date 1 is required");
      return;
    }

    setLoadingReport(true);
    setActiveModal(null);
    let dateFormat = periodType === 'year' ? "YYYY" : "YYYY-MM";

    axios.get(baseURLs.API_URL + "/reports/income-statement", {
      params: {
        business_id: businessID,
        accounting_type: accountingType, 
        period_type: periodType, 
        period_date_1: moment(periodDate1).format(dateFormat), 
		    period_date_2: !periodDate2 ? null : moment(periodDate2).format(dateFormat), 
		    period_date_3: !periodDate3 ? null : moment(periodDate3).format(dateFormat), 
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      let responseInfo = response.data;
      let report = responseInfo.data.report;

      let resultMap = {};

      report.forEach(item => {
        const period = item.period;
        
        item.operating_expenses.categories.forEach(cat => {
          if (!resultMap[cat.category]) {
            resultMap[cat.category] = {
              category: cat.category,
              info: []
            };
          }
          
          resultMap[cat.category].info.push({
            period: period,
            total_amount: cat.total_amount
          });
        });
      });

      resultMap = Object.values(resultMap);
      resultMap.sort((a, b) => a.category.localeCompare(b.category));

      // get all operating expenses categories
      setExpensesCategory(resultMap);

      setIncomeStatementReport(report);
      setLoadingReport(false);
            
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setLoadingReport(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const printDivContent = (divId, title) => {
    const divContents = document.getElementById(divId).innerHTML;
    let a = window.open('', '_blank');
    a.document.write('<html>');
    a.document.write('<head>');
    a.document.write('<title>'+title+'</title>');
    a.document.write('<link rel="stylesheet" href="/custom/income-statement.css?ver=0.2">');
    a.document.write('</head >');
    a.document.write('<body style="background: #ffffff;">');
    a.document.write(divContents);
    a.document.write('</body></html>');
    a.document.close();
    setTimeout(() => {
      a.print();
    }, 500);
  }

  useEffect(() => {
    setLoading(true);
    const businessID = parseInt(match.params.businessID, 10);
    if ( !Number.isInteger(businessID) ) {
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;      
    } 

    localStorage.setItem('current_business_id', businessID);
    let businesses = JSON.parse(localStorage.getItem('my_businesses'));
    let currentBusiness = businesses?.find((business) => business.business_id == businessID);
    
    if(currentBusiness == undefined || null || ""){
      history.push(`${process.env.PUBLIC_URL}/not-found`);
      return;
    }

    setBusinessInfo(currentBusiness);
    setBusinessID(businessID);
    getIncomeStatement(businessID);
    setLoading(false);
  }, [match.params.businessID]);

  return (<React.Fragment>
    <Head title="Profit & Loss Report" />
    <Content>
    {
      loading ?
      <Block className="nk-block-middle nk-auth-body text-center wide-xs">
        <div className="inner-pre-loader">
          <Spinner  color="dark" />          
        </div>
      </Block>
      :
      <>
      <BlockHead size="sm">
        <div className="nk-block-between">
          <BlockHeadContent>
            <BlockDes className="text-soft">
              <p>{businessInfo.name}</p>
            </BlockDes>
            <BlockTitle page tag="h3">
              Profit &amp; Loss Report
            </BlockTitle>
            
          </BlockHeadContent>
          <BlockHeadContent>
            <div className="toggle-wrap nk-block-tools-toggle">
              <Button
                className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                onClick={() => updateSm(!sm)}
              >
                <Icon name="more-v"></Icon>
              </Button>
              <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                <ul className="nk-block-tools g-3">
                  <li className="nk-block-tools-opt">
                    <Button color="primary" disabled={loadingReport} onClick={() => toggleModal('filterModal')}>
                      <Icon name="calender-date" />
                      <span>Filter Report</span>
                    </Button>
                    {
                      activeModal === 'filterModal' &&
                      <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                        <ModalHeader
                          toggle={() => toggleModal('filterModal')}
                          close={
                            <button className="close" onClick={() => toggleModal('filterModal')}>
                              <Icon name="cross" />
                            </button>
                          }
                        >
                          Filter Report
                        </ModalHeader>
                        <ModalBody>
                        {errorMessage && (
                            <div className="mb-3">
                              <Alert color="danger" className="alert-icon">
                                {" "}
                                <Icon name="alert-circle" /> {errorMessage}{" "}
                              </Alert>
                            </div>
                          )}
                          <div className="form-group">
                            <label className="form-label">Accounting Type
                              <Button type="button" id="account_type_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                                <Icon name="info" />
                              </Button>
                              <UncontrolledPopover target="account_type_info_popover" placement="bottom" trigger="focus">
                                <PopoverBody>
                                  <div className="mb-2"><b>Cash Accounting:</b> Shows revenue and expenses when you physically receive or pay money. It's like keeping track of your wallet transactions.</div>                                  
                                  <div><b>Accrual Accounting:</b> Reflects revenue and expenses when they happen, regardless of cash movement. It's like tracking what you owe or are owed, giving a broader financial picture.</div>
                                </PopoverBody>
                              </UncontrolledPopover>
                            </label>                          
                            <div className="form-control-wrap">
                              <Select size="large"                          
                                placeholder="Select period type"
                                defaultValue={accountingType}
                                style={{ width: "100%" }} 
                                name="accounting_type"
                                onChange={(value) => setAccountingType(value)}
                                filterOption={(input, option) =>
                                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                options={[
                                  {value: "cash_accounting", label: "Cash Accounting"},
                                  {value: "accrual_accounting", label: "Accrual Accounting"},
                                ]} 
                                showSearch /> 
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="form-label">Period Type</label>                          
                            <div className="form-control-wrap">
                              <Select size="large"                          
                                placeholder="Select period type"
                                defaultValue={periodType}
                                style={{ width: "100%" }} 
                                name="period_type"
                                onChange={(value) => setPeriodType(value)}
                                filterOption={(input, option) =>
                                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                options={[
                                  {value: "month", label: "Month"},
                                  {value: "year", label: "Year"},
                                ]} 
                                showSearch /> 
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="form-label">Period 1</label>                          
                            <div className="form-control-wrap">
                              {
                                periodType === 'month' ?
                                <DatePicker
                                  selected={periodDate1}
                                  onChange={(date) => setPeriodDate1(date)}
                                  dateFormat="MM/yyyy"                                    
                                  showMonthYearPicker
                                  isClearable
                                  className="form-control form-control-lg date-picker"
                                />
                                :
                                <DatePicker
                                  selected={periodDate1}
                                  onChange={(date) => setPeriodDate1(date)}
                                  dateFormat="yyyy"
                                  showYearPicker
                                  isClearable
                                  className="form-control form-control-lg date-picker"
                                />
                              }
                              
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="form-label">Period 2</label>                          
                            <div className="form-control-wrap">
                              {
                                periodType === 'month' ?
                                <DatePicker
                                  selected={periodDate2}
                                  onChange={(date) => setPeriodDate2(date)}
                                  dateFormat="MM/yyyy"                                    
                                  showMonthYearPicker
                                  isClearable
                                  className="form-control form-control-lg date-picker"
                                />
                                :
                                <DatePicker
                                  selected={periodDate2}
                                  onChange={(date) => setPeriodDate2(date)}
                                  dateFormat="yyyy"
                                  showYearPicker
                                  isClearable
                                  className="form-control form-control-lg date-picker"
                                />
                              }
                              
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="form-label">Period 3</label>                          
                            <div className="form-control-wrap">
                              {
                                periodType === 'month' ?
                                <DatePicker
                                  selected={periodDate3}
                                  onChange={(date) => setPeriodDate3(date)}
                                  dateFormat="MM/yyyy"                                    
                                  showMonthYearPicker
                                  isClearable
                                  className="form-control form-control-lg date-picker"
                                />
                                :
                                <DatePicker
                                  selected={periodDate3}
                                  onChange={(date) => setPeriodDate3(date)}
                                  dateFormat="yyyy"
                                  showYearPicker
                                  isClearable
                                  className="form-control form-control-lg date-picker"
                                />
                              }
                              
                            </div>
                          </div>
                          <div className="form-group">
                            <Button color="primary" onClick={() => {getIncomeStatement(businessID);}} size="md">
                            {loading ? <Spinner size="sm" color="light" /> : "Apply Filter"}
                            </Button>
                          </div>
                        </ModalBody>
                        <ModalFooter className="bg-light justify-content-start">
                          <span className="sub-text">Use the form above to filter your profit and loss report.</span>
                        </ModalFooter>
                      </Modal>                      
                    }
                  </li>
                  <li className="nk-block-tools-opt">
                    <Button color="light" outline disabled={loadingReport} onClick={() => {printDivContent("profit_loss", `${businessInfo.name} - Profit & Loss Report`)}}>
                      <Icon name="printer" />
                      <span>Print</span>
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </BlockHeadContent>
        </div>
      </BlockHead>

      {
        loadingReport ?
        <Block className="nk-block-middle nk-auth-body text-center wide-xs">
          <div className="inner-pre-loader">
            <Spinner  color="dark" />          
          </div>
        </Block>
        :
        <Block>
          <div id="profit_loss">
            <Row className="g-gs justify-content-center mt-3">
              <Col lg="10">
                <PreviewAltCard>
                  <div className="card-title text-center mt-3">
                    <h4 className="title">{businessInfo.name}</h4>
                    <p className="mb-0">{businessInfo.location}</p>
                    <p>{businessInfo.phone_number}</p>
                    <h6 className="title mt-4">Profit &amp; Loss Report</h6>
                    <p><small className="text-muted">({`${accountingType === 'cash_accounting' ? `Cash Accounting` : `Accrual Accounting`}`})</small></p>
                    <p>For the Period Ended 
                      {
                        incomeStatementReport.map((data, index) => {
                          let period = index === 0 ? `${data.period}` : `, ${data.period}`;
                          return (
                            <span className="ps-1 fw-bold" key={index}>{period}</span>
                          )
                        })
                      }
                    </p>
                  </div>
                  <div className="pl-container">
                    <table className="table table-bordered text-left">
                      <thead>
                        <tr>
                          <th scope="col">Revenue</th>
                          {
                            incomeStatementReport.map((data, index) => {
                              return (
                                <th key={index} className="text-right" scope="col">{data.period}</th>
                              );
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          accountingType !== 'cash_accounting' &&
                          <>
                            <tr>
                              <td className="ps-5">Products Sold</td>
                              {
                                incomeStatementReport.map((data, index) => {
                                  return (
                                    <td key={index} className="text-right"><small>{businessInfo.currency}</small> {data.revenue.products_sold}</td>
                                  );
                                })
                              }
                            </tr>
                            <tr>
                              <td className="ps-5">Services</td>
                              {
                                incomeStatementReport.map((data, index) => {
                                  return (
                                    <td key={index} className="text-right"><small>{businessInfo.currency}</small> {data.revenue.services}</td>
                                  );
                                })
                              }
                            </tr>
                            <tr>
                              <td className="ps-5">Custom Items Sold</td>
                              {
                                incomeStatementReport.map((data, index) => {
                                  return (
                                    <td key={index} className="text-right"><small>{businessInfo.currency}</small> {data.revenue.custom_items_sold}</td>
                                  );
                                })
                              }
                            </tr>
                            <tr>
                              <td className="ps-5">Taxes</td>
                              {
                                incomeStatementReport.map((data, index) => {
                                  return (
                                    <td key={index} className="text-right"><small>{businessInfo.currency}</small> {data.revenue.taxes}</td>
                                  );
                                })
                              }
                            </tr>
                            <tr>
                              <td className="ps-5">Delivery Cost</td>
                              {
                                incomeStatementReport.map((data, index) => {
                                  return (
                                    <td key={index} className="text-right"><small>{businessInfo.currency}</small> {data.revenue.delivery}</td>
                                  );
                                })
                              }
                            </tr>
                            <tr>
                              <td className="ps-5">Less: Discounts</td>
                              {
                                incomeStatementReport.map((data, index) => {
                                  return (
                                    <td key={index} className="text-right">
                                      <span className="text-danger">- <small>{businessInfo.currency}</small> {data.revenue.discounts}</span>
                                    </td>
                                  );
                                })
                              }
                            </tr>
                          </>
                        }

                        <tr className="fs-15px">
                          <th scope="row">Total Revenue</th>
                          {
                            incomeStatementReport.map((data, index) => {
                              return (
                                <th key={index} scope="row" className="text-right"><small>{businessInfo.currency}</small> {data.revenue.total_revenue}</th>
                              );
                            })
                          }
                        </tr>
                        <tr><td colSpan="4" className="p-3"></td></tr>
                        <tr className="fs-15px">
                          <th>Cost of Goods Sold (COGS)</th>
                          {
                            incomeStatementReport.map((data, index) => {
                              return (
                                <th key={index} className="text-right"><small>{businessInfo.currency}</small> {data.cost_of_goods_sold}</th>
                              );
                            })
                          }
                        </tr> 
                        <tr><td colSpan="4" className="p-3"></td></tr>
                        <tr className="fs-16px">   
                          <th scope="row">Gross Profit / (Loss)</th>         
                          {
                            incomeStatementReport.map((data, index) => {
                              return (
                                  <td key={index} className="text-right">
                                    {
                                      data.is_gross_profit ?  
                                      <span className="text-success"><small>{businessInfo.currency}</small> {data.gross_profit}</span>
                                      : 
                                      <span className="text-danger">(<small>{businessInfo.currency}</small> {data.gross_profit.replace('-', '')})</span>
                                    
                                    }
                                  </td>
                              );
                            })
                          }
                        </tr>
                        <tr><td colSpan="4" className="p-3"></td></tr>
                        <tr><th colSpan="4" scope="row">Operating Expenses</th></tr>                      
                                          
                          {
                            expensesCategory.map((data, index) => {
                              return (
                                <tr key={index}>  
                                  <td className="ps-5">{data.category}</td>
                                  {
                                    incomeStatementReport.map((statement, pIndex) => {
                                      let categoryInfo = data.info.filter((info) => info.period === statement.period);
                                      let amount = categoryInfo.length === 0 ? '0.00' : categoryInfo[0].total_amount
                                      return (                                      
                                        <td className="text-right" key={pIndex}><small>{businessInfo.currency}</small> {amount}</td>                                     
                                      )
                                    })
                                  }
                                </tr>
                              );
                            })
                          }
                        <tr className="fs-15px">
                          <th scope="row">Total Operating Expenses</th>
                          {
                            incomeStatementReport.map((data, index) => {
                              return (
                                <td key={index} className="text-right"><small>{businessInfo.currency}</small> {data.operating_expenses.total_operating_expenses}</td>
                              );
                            })
                          }
                        </tr>
                        <tr><td colSpan="4" className="p-3"></td></tr>
                        <tr className="fs-16px">  
                          <th scope="row">Net Profit / (Loss)</th>          
                          {
                            incomeStatementReport.map((data, index) => {
                              return (
                                <td key={index} className="text-right">
                                  {
                                    data.is_net_profit ?  
                                    <span className="text-success"><small>{businessInfo.currency}</small> {data.net_profit}</span>
                                    : 
                                    <span className="text-danger">(<small>{businessInfo.currency}</small> {data.net_profit.replace('-', '')})</span>
                                  
                                  }
                                </td>
                              );
                            })
                          }
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </PreviewAltCard>
              </Col>
            </Row>
          </div>
        </Block>
      }
      
      
      </>
    }
    </Content>
    
  </React.Fragment>)
}

export default ProfitAndLoss;