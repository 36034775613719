import React, { useState, useEffect } from "react";
import { Empty, Switch, Select, Popover } from 'antd';
import { baseURLs } from '../../../utils/Constants';
import axios from 'axios';
import { Link } from "react-router-dom";
import moment from 'moment';
import imagePlaceholder from "../../../images/product_placeholder.png";
import { getAxiosHeaders, getQueryParams, findUpper } from "../../../utils/Utils";
import { Badge, 
  UncontrolledPopover, 
  PopoverHeader, 
  PopoverBody, 
  DropdownToggle, 
  DropdownMenu, 
  Card, 
  UncontrolledDropdown, 
  DropdownItem, 
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from "reactstrap";
import {
  Block,
  Icon,
  Button,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  UserAvatar,
} from "../../../components/Component";
import {
  AlertModal,
  SuccessModal,
  LoadingModal
} from "../AlertModals";
import { TableLoader } from "../../../utils/Loaders";
import { PaginationWithOnclick } from "./Pagination";

export const ProductsTable = ({ history, businessID, currency, ...props }) => {
  const [tableData, setTableData] = useState({
    meta: {
      total_records: 0,
      total_quantity: 0,
      total_products_in_stock: 0,
      total_products_out_of_stock: 0,
      selling_price_total_amount: 0,
      cost_price_total_amount: 0,
      total_expired_products: 0,
      total_soon_to_expired_products: 0,
    }, 
    products: []
  });
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [hasFilters, setHasFilters] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedBoxes, setCheckedBoxes] = useState([]);
   const [filters, setFilters] = useState({
    business: businessID,
    page: 1,
    product_name: '',
    SKU: '',
    barcode: '',
    category: '',
    status: 'all',
    expired: 'no'
  });
  const [categories, setCategories] = useState([])
  const [downloadLink, setDownloadLink] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeModal, setActiveModal] = useState(null);
  const [hideExpiredProductsAlert, setHideExpiredProductsAlert] = useState(false);
 
  const toggleModal = (modal) => {
    if (activeModal === modal) {
      setActiveModal(null);
    } else {
      setActiveModal(modal);
    }
  };

  const onInputChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const onSelectChange = (value, name) => {
    setFilters({ ...filters, [name]: value });
  };

  const selectAll = () => {
    setCheckAll(prevCheckAll => {
      if (prevCheckAll) {
        setCheckedBoxes([]);
      } else {
        const allIDs = tableData.products.map(item => item.product_id);
        setCheckedBoxes(allIDs);
      }
      return !prevCheckAll;
    });
  }

  const handleToggle = () => {
    setFilters(prevFilters => ({ ...prevFilters, expired: prevFilters.expired === 'yes' ? 'no' : 'yes' }));
  }

  const handleCheckboxChange = (value) => {
    setCheckedBoxes(prevBoxes => {
      if (prevBoxes.includes(value)) {
          return prevBoxes.filter(id => id !== value);
      } else {
          return [...prevBoxes, value];
      }
    });
    if (checkAll) {
        setCheckAll(false);
    }
  };

  const currentUrl = (filters) => {
    let product_name = filters.product_name.length > 0 ? `&ps=${filters.product_name}` : ``;
    let SKU_search = filters.SKU.length > 0 ? `&sku=${filters.SKU}` : ``;
    let barcode_search = filters.barcode.length > 0 ? `&bc=${filters.barcode}` : ``;
    let category_search = filters.category.length > 0 ? `&c=${filters.category}` : ``;
    let status_search = filters.status.length > 0 ? `&st=${filters.status}` : ``;
    let expired_search = filters.expired === 'yes' ? `&exp=${filters.expired}` : ``;
    
    if( (product_name !== '' ||
    SKU_search !== '' ||
    barcode_search !== '' ||
    category_search !== '' ||
    expired_search !== '' ||
    filters.status !== 'all') && !hasFilters) {
      setHasFilters(true)
    }

    let params = `${product_name}${SKU_search}${barcode_search}${category_search}${status_search}${expired_search}`;
    let url = `${process.env.PUBLIC_URL}${window.location.pathname}?p=${filters.page}${params}`;
    history.replace(url);
  }

  const handleExpirationClick = () => {
    setHideExpiredProductsAlert(true);
    let params = filters;
    params.page = 1;
    params.expired = 'yes';
    setFilters({...params});
    getProducts(params);
  };

  const loadNextPage = (page) => {
    let params = filters;
    params.page = page;
    setFilters({...params});
    getProducts(params)
  }

  const resetFilter = () => {
    let params = {
      business: businessID,
      page: 1,
      product_name: '',
      SKU: '',
      barcode: '',
      category: '',
      status: 'all',
      expired: 'no'
    };
  
    toggleModal('filterModal');
    setHasFilters(false);
    setFilters({ ...params });
    getProducts(params);
  }

  const filterProducts = () => {
    toggleModal('filterModal');
    setHasFilters(true);
    let params = filters;
    params.page = 1;
    setFilters({...params});
    getProducts(filters);
  }

  const getProducts = (filters) => {
    setLoading(true);
    axios.get(baseURLs.API_URL + "/products/variants", {
      params: {
        business_id: businessID,
        page: filters.page,
        product_name: filters.product_name,
        SKU: filters.SKU,
        barcode: filters.barcode,
        category: filters.category,
        status: filters.status,
        expired: filters.expired
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
            total_quantity: 0,
            total_products_in_stock: 0,
            total_products_out_of_stock: 0,
            selling_price_total_amount: 0,
            cost_price_total_amount: 0,
            total_expired_products: 0,
            total_soon_to_expired_products: 0,
          }, 
          products:[]
        });
      } else {
        let responseInfo = response.data;
        setTotalPages(Math.ceil(responseInfo.data.meta.total_records / 10));
        setTableData(responseInfo.data);
      }

      setLoading(false);
      currentUrl(filters);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setTotalPages(0);
        setTableData({
          meta: {
            total_records: 0,
            total_quantity: 0,
            total_products_in_stock: 0,
            total_products_out_of_stock: 0,
            selling_price_total_amount: 0,
            cost_price_total_amount: 0,
            total_expired_products: 0,
            total_soon_to_expired_products: 0,
          }, 
          products:[]
        });

        setLoading(false);
        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteProductsByIDs = () => {
    toggleModal('deleteLoadingModal');

    let _checkedBoxes = checkedBoxes;
    if(_checkedBoxes.length > 0){
      _checkedBoxes = _checkedBoxes.map((id) => parseInt(id));
    }

    axios.delete(baseURLs.API_URL + "/products/by-ids", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        products: JSON.stringify(_checkedBoxes),
      }
    })
    .then((response) => {
      let responseInfo = response.data;
      let successfulIDs = responseInfo.data.successful_product_ids;
      let failedIDs = responseInfo.data.failed_product_ids;

      if(successfulIDs.length > 0 && failedIDs.length === 0){
        getProducts(filters);
        toggleModal('successDeleteModal');

      } else if(successfulIDs.length > 0 && failedIDs.length > 0) {
        getProducts(filters);
        setErrorMessage(`${successfulIDs.length} ${successfulIDs.length > 1 ? `products` : `product`} were successfully deleted and ${failedIDs.length} ${failedIDs.length > 1 ? `products` : `product`} failed to get deleted`);
        toggleModal('deleteErrorModal');

      } else {
        setErrorMessage(`Error deleting ${failedIDs.length > 1 ? `products` : `product`}. The selected ${failedIDs.length > 1 ? `products were` : `product was`} not found.`);
        toggleModal('deleteErrorModal');
      }

      setCheckedBoxes([]);
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const deleteProductsByFilter = () => {
    toggleModal('deleteLoadingModal');

    axios.delete(baseURLs.API_URL + "/products/by-filters", {
      headers: getAxiosHeaders().headers,
      data: {
        business_id: businessID,
        product_name: filters.product_name,
        SKU: filters.SKU,
        barcode: filters.barcode,
        category: filters.category,
        status: filters.status,
        expired: filters.expired
      }
    })
    .then((response) => {
      getProducts(filters);
      toggleModal('successDeleteModal');
      setCheckAll(false);
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('deleteErrorModal');
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleSelectedRecordsAction = (action) => {
    if(!checkAll && checkedBoxes.length === 0){
      toggleModal('noSelectionAlertModal');
      return;
    }

    toggleModal('deleteAlertModal');    
  }

  const downloadProducts = () => {
    setErrorMessage("");
    toggleModal('loadingModal');
    
    axios.get(baseURLs.API_URL + "/products/download", {
      params: {
        business_id: businessID,
        product_name: filters.product_name,
        SKU: filters.SKU,
        barcode: filters.barcode,
        category: filters.category,
        status: filters.status,
        expired: filters.expired
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 204) {
        setErrorMessage('No product found');        
        toggleModal('alertModal');
      } else {
        let responseInfo = response.data;
        setDownloadLink(responseInfo.data.download_link);
        toggleModal('downloadModal');
      }

    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
        toggleModal('alertModal');

      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const getProductsCategory = (businessID) => {

    axios.get(baseURLs.API_URL + "/products/category", {
      params: {
        business_id: businessID,
        limit: 'all'
      },
      headers: getAxiosHeaders().headers
    })
    .then((response) => {
      if (response.status === 200) {
        let responseInfo = response.data;
        let categories = responseInfo.data.categories;
        let _categories = categories.map(category => category.category_name)
        setCategories([..._categories]);
      }
    }).catch((error) => {
      try{
        let errorResponse = error.response.data;

        if(error.response.status === 401){
          history.push(`${process.env.PUBLIC_URL}/expired-session`);
          return;
        }

        if(error.response.status === 404){
          history.push(`${process.env.PUBLIC_URL}/not-found`);
          return;
        }

        if(error.response.status === 403){
          history.push(`${process.env.PUBLIC_URL}/unauthorized/b/${businessID}`);
          return;
        }

        if(error.response.status === 402){
          history.push(`${process.env.PUBLIC_URL}/subscription/b/${businessID}`);
          return;
        }

        let errorMessage = 'Error: Could not connect to server';
        if(errorResponse.hasOwnProperty("error")){
          errorMessage = errorResponse.error;
        }

        setErrorMessage(errorMessage);
      }catch(e){
        console.log(e);
        // history.push(`${process.env.PUBLIC_URL}/server-offline`);
      }
    });
  }

  const handleSingleDelete = (productID) => {
    setCheckedBoxes([productID]);

    setTimeout(() => {
      toggleModal('deleteAlertModal');       
    }, 500);
  }

  useEffect(() => {
    setLoading(true);
    let url_string = window.location.href;
    let url = new URL(url_string);
    let queryParams = getQueryParams(url);

    let page = queryParams.hasOwnProperty('p') ? queryParams.p : 1;
    let product_name = queryParams.hasOwnProperty('ps') ? queryParams.ps : '';
    let SKU = queryParams.hasOwnProperty('sku') ? queryParams.sku : '';
    let barcode = queryParams.hasOwnProperty('bc') ? queryParams.bc : '';
    let category = queryParams.hasOwnProperty('c') ? queryParams.c : '';
    let status_search = queryParams.hasOwnProperty('st') ? queryParams.st : 'all';
    let expired_search = queryParams.hasOwnProperty('exp') ? queryParams.exp : 'no';

    let params = filters;
    params.page = page;
    params.product_name = product_name;
    params.SKU = SKU;
    params.barcode = barcode;
    params.category = category;
    params.status = status_search;
    params.expired = expired_search;

    setFilters(params);
    getProducts(params);
    getProductsCategory(businessID);
  }, []);

  
  return (
    <Block>
      {
        (tableData.meta.total_expired_products > 0 || tableData.meta.total_soon_to_expired_products > 0) && !hideExpiredProductsAlert && 
        <div className="card-bordered card mb-2">
          <div className="card-inner py-lg-1">
            <div className="nk-help">
              <div className="nk-help-img m-0">
                <lord-icon
                  src="https://cdn.lordicon.com/pjbzvmli.json"
                  trigger="loop"
                  delay="1000"
                  style={{width:"120px",height:"120px"}}>
                </lord-icon>
              </div>
              <div className="nk-help-text">
                <h5>Product Expiration Alert</h5>
                {
                  tableData.meta.total_expired_products > 0 &&
                  <p className="text-soft">{tableData.meta.total_expired_products} of your {tableData.meta.total_expired_products > 1 ? `products have` : `product has`} expired.</p>
                }
                {
                  tableData.meta.total_soon_to_expired_products > 0 &&
                  <p className="text-soft">{tableData.meta.total_soon_to_expired_products} of your {tableData.meta.total_soon_to_expired_products > 1 ? `products ` : `product `} will be expiring soon.</p>
                }
              </div>
              <div className="nk-help-action">
              <button className="btn btn-outline-primary" onClick={handleExpirationClick}>
                View Products
              </button>
              </div>
            </div>
          </div>
        </div>
      }
      {
        loading ?
        <div className="card-inner p-0">
          <TableLoader />
        </div>
        :
        <>
          {
            tableData.products.length > 0 &&
            <div className="row g-gs mb-5">
              <div className="col-md-12">
                <Card className="card-bordered">
                  <div className="card-inner">
                    <div className="analytic-data-group analytic-ov-group g-3 justify-content-md-evenly ">
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-success">Products In-Stock</div>
                        <div className="amount">{tableData.meta.total_products_in_stock}</div>
                      </div>
                      <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-danger">Products Out-of-Stock</div>
                        <div className="amount">{tableData.meta.total_products_out_of_stock}</div>
                      </div>
                      <div className="btn-toolbar-sep me-3 ms-3 d-none d-sm-inline"></div>
                      <div className="analytic-data text-md-center me-4">
                        <div className="title text-dark">Total Stock Amount</div>
                        <div className="amount">
                          <small className="text-muted fw-light">{currency}</small> 
                          {tableData.meta.cost_price_total_amount}
                          <Button type="button" id="products_value_info_popover" className="btn-round btn-icon text-end p-0" color="muted" size="sm">
                            <Icon name="info" />
                          </Button>
                          <UncontrolledPopover target="products_value_info_popover" placement="bottom" trigger="focus">
                            <PopoverBody>
                              <div>
                                <small>Products Value at Cost Price:</small><br/> 
                                <b><small className="text-muted fw-light me-1">{currency}</small>{tableData.meta.cost_price_total_amount}</b>
                              </div>
                              <div className="my-1">
                                <small>Products Value at Selling Price:</small><br/> 
                                <b><small className="text-muted fw-light me-1">{currency}</small>{tableData.meta.selling_price_total_amount}</b>
                              </div>
                              <div><small>Note: The totals are base on the filter</small></div>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>   
              </div>
            </div>
          }
          <Card className="card-bordered card-stretch">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h6 className="title">
                      {`${tableData.meta.total_records} ${tableData.meta.total_records > 1 ? `Products` : `Product`}`}
                    </h6>
                  </div>
                  <div className="card-tools me-n1">
                    <ul className="btn-toolbar">
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={() => toggleModal('filterModal')} title="Filter">
                          { hasFilters && <div className="dot dot-primary"></div> }
                          <Icon name="search"></Icon>
                        </Button>
                        {
                          activeModal === "filterModal" &&
                          <Modal isOpen={true} toggle={() => toggleModal('filterModal')}>
                            <ModalHeader
                              toggle={() => toggleModal('filterModal')}
                              close={
                                <button className="close" onClick={() => toggleModal('filterModal')}>
                                  <Icon name="cross" />
                                </button>
                              }
                            >
                              Products Filter
                            </ModalHeader>
                            <ModalBody>
                              <form>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="product_name">
                                    Product Name
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="product_name" className="form-control form-control-lg" id="product_name" defaultValue={filters.product_name} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="phone_search">
                                    Category
                                  </label>
                                  <div className="form-control-wrap">
                                    <Select size="large"                          
                                      placeholder="Select a category"
                                      defaultValue={filters.category}
                                      style={{ width: "100%" }} 
                                      name="category"
                                      onChange={(value) => onSelectChange(value, "category")}
                                      filterOption={(input, option) =>
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      options={categories.map((category) => ({
                                        key: category,
                                        label: category,
                                        value: category,
                                      }))}
                                      allowClear
                                      showSearch /> 
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="phone_search">
                                    Status
                                  </label>
                                  <div className="form-control-wrap">
                                    <Select size="large"                          
                                      placeholder="Select status"
                                      defaultValue={filters.status}
                                      style={{ width: "100%" }} 
                                      name="status"
                                      onChange={(value) => onSelectChange(value, "status")}
                                      filterOption={(input, option) =>
                                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      options={[
                                        {value: "all", label: "All"},
                                        {value: "in_stock", label: "In-Stock"},
                                        {value: "low_stock", label: "Low In-Stock"},
                                        {value: "out_of_stock", label: "Out-of-Stock"},
                                      ]} 
                                      showSearch /> 
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="SKU">
                                    SKU
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="SKU" className="form-control form-control-lg" id="SKU" defaultValue={filters.SKU} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="form-label" htmlFor="barcode">
                                    Barcode
                                  </label>
                                  <div className="form-control-wrap">
                                    <input type="text" onChange={onInputChange} name="barcode" className="form-control form-control-lg" id="barcode" defaultValue={filters.barcode} />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="custom-control custom-switch">
                                    <input 
                                        type="checkbox" 
                                        checked={filters.expired === 'yes' ? true : false} 
                                        onChange={handleToggle} 
                                        className="custom-control-input" 
                                        aria-label="Toggle filter for expired or soon to expire products" 
                                        id="expired" 
                                    />
                                    <label className="custom-control-label" htmlFor="expired"> 
                                        Expired or Soon to Expire 
                                    </label>
                                  </div>
                                </div>
                                <div className="form-group mt-5">
                                  <Button color="primary" type="submit" onClick={(ev) => { ev.preventDefault(); filterProducts();} } size="md">
                                    Apply Filter
                                  </Button>

                                  {
                                    hasFilters &&
                                    <Button className="ms-3 text-muted" color="lighter" onClick={(ev) => { ev.preventDefault(); resetFilter();} } size="md">
                                      Reset Filter
                                    </Button>
                                  }
                                </div>
                              </form>
                            </ModalBody>
                            <ModalFooter className="bg-light justify-content-start">
                              <span className="sub-text">Use the form above to filter your products.</span>
                            </ModalFooter>
                          </Modal>
                        }
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" title="Reload" onClick={() => {getProducts(filters)}}>
                          <Icon name="redo"></Icon>
                        </Button>
                      </li>
                      <li className="btn-toolbar-sep"></li>
                      <li>
                        <Button className="btn-icon btn-trigger" onClick={downloadProducts} title="Download">
                          <Icon name="download-cloud"></Icon>
                        </Button>
                        { activeModal === "loadingModal" && <LoadingModal showModal={true} headerText={"Preparing Products"} descriptionText={"Please wait while your products list is being prepared for download."} /> }
                        
                        { 
                          activeModal === "downloadModal" &&
                          <SuccessModal showModal={true} toggleModal={() => toggleModal('downloadModal')}
                            headerText={"Products Ready"} descriptionText={"Products CSV is ready for download."} 
                            leftButtonText={"Download"} leftButtonOnClick={() => {window.open(downloadLink, '_blank').focus(); toggleModal('downloadModal');}}
                          />
                        }
                        
                        {
                          activeModal === "alertModal" &&
                          <AlertModal showModal={true} toggleModal={() => toggleModal('alertModal')}
                            headerText={"Download Failed"} descriptionText={errorMessage}                       
                          />
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>          
              <div className="p-0">
                {
                  tableData.products.length === 0 ?
                  <div className="text-center m-5">
                    <div className="price-plan-media"><Empty image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" imageStyle={{height: 60}} description={false} /></div>
                    <div className="price-plan-info">
                      <h5 className="title fw-normal">No products found</h5><span>Add a product using the button below.</span>
                    </div>
                    <div className="price-plan-action">
                      <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}/add`}>
                        <button className="btn btn-outline-primary">Add a Product</button>
                      </Link>                    
                    </div>
                  </div>
                  :
                  <>      
                    <DataTableBody>
                      <DataTableHead className="bg-lighter fw-bold">
                        <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input
                              type="checkbox"
                              checked={checkAll}
                              onChange={selectAll}
                              className="custom-control-input table-checkbox"
                              id="select_all" />
                            <label className="custom-control-label" htmlFor="select_all"/>
                          </div>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="sub-text">Product</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Cost Price</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Selling Price</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Quantity</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="sub-text">Status</span>
                        </DataTableRow>
                        <DataTableRow size="xl">
                          <span className="sub-text">Last Updated</span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools text-end">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt text-capitalize" style={{letterSpacing: "normal"}}>
                                <li>
                                  <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSelectedRecordsAction('cancel')}>
                                    <Icon name="trash"></Icon>
                                    <span>Delete Selected Products</span>
                                    {
                                      activeModal === "deleteAlertModal" &&
                                      <AlertModal showModal={true} toggleModal={() => toggleModal('deleteAlertModal')}
                                        headerText={checkedBoxes.length > 1 ? `Delete Products` : `Delete Product`} 
                                        descriptionText={`${checkAll ? 
                                        `Are you sure you want to delete all ${tableData.meta.total_records} products. This action will also delete all the variants of the product and can not be reverted.` 
                                        :
                                        `Are you sure you want to delete ${checkedBoxes.length > 1 ? `these ${checkedBoxes.length} selected products` : `this selected product`}. This action will also delete all the variants of the product and can not be reverted.`}`}
                                        leftButtonText={`Delete`}
                                        leftButtonOnClick={checkAll ? deleteProductsByFilter : deleteProductsByIDs} />
                                    }
                                  </DropdownItem>
                                </li>
                              </ul>
                              {
                                activeModal === "noSelectionAlertModal" &&
                                <AlertModal showModal={true} toggleModal={() => toggleModal('noSelectionAlertModal')}
                                  headerText={`Error`} 
                                  descriptionText={`No product has been selected. Select a product and try again.`}
                                />
                              }

                              { activeModal === "deleteLoadingModal" && <LoadingModal showModal={true} headerText={`Deleting...`} descriptionText={`Please wait while the ${checkedBoxes.length > 1 ? `products are` : `product is`} being deleted`} /> }
                        
                              { 
                                activeModal === "successDeleteModal" &&
                                <SuccessModal showModal={true} toggleModal={() => toggleModal(null)}
                                  headerText={`Deleted`} descriptionText={`Product(s) deleted successfully.`} 
                                />
                              }
                              
                              {
                                activeModal === "deleteErrorModal" &&
                                <AlertModal showModal={true} toggleModal={() => toggleModal(null)}
                                  headerText={"Error"} descriptionText={errorMessage}                       
                                />
                              }
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </DataTableRow>
                      </DataTableHead>
                      {
                        tableData.products.map((data, index) => {
                          let productStatus = <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="success">In-Stock</Badge></div>;                            
                          let productStatusDot = <span className="dot dot-success ms-1"></span>;

                          switch (data.stock_status) {
                            case 'in_stock':
                              productStatus = data.quantity > data.restock_level ? <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="success">In-Stock</Badge></div> : <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="warning">Low-in-Stock</Badge></div>;
                              productStatusDot = data.quantity > data.restock_level ? <span className="dot dot-success ms-1"></span> : <span className="dot dot-warning ml-1"></span>;
                              break;
                            
                            case 'out_of_stock':
                              productStatus = <div className="tb-tnx-status text-end"><Badge className="badge-dot" color="danger">Out-of-Stock</Badge></div>;
                              productStatusDot = <span className="dot dot-danger ms-1"></span>;
                              break;
                          }
                          
                          return (
                            <DataTableItem key={index}>
                              <DataTableRow className="nk-tb-col-check">
                                <div className="custom-control custom-control-sm custom-checkbox">
                                  <input
                                    type="checkbox"
                                    checked={checkAll || checkedBoxes.includes(data.parent_product_id)}
                                    onChange={() => handleCheckboxChange(data.parent_product_id)}
                                    className="custom-control-input table-checkbox"
                                    id={`item${data.product_id}`} 
                                  />
                                  <label className="custom-control-label" htmlFor={`item${data.product_id}`} />
                                </div>
                              </DataTableRow>
                              <DataTableRow>
                                <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}/details/${data.product_id}`}>
                                  <div className="user-card">
                                    <span className="me-1 text-muted">{filters.page == 1 ? index + 1 : ((filters.page - 1) * 10) + (index + 1)}.</span>
                                    <UserAvatar className="sq md" theme="lighter" text={findUpper(data.product_name)} image={data.images.length > 0 ? data.images[0].url : imagePlaceholder} imagePlaceholder={imagePlaceholder} />
                                    <div className="user-info">
                                      <span className={`tb-lead fw-normal`}>
                                        {data.product_name}{" "}
                                        {
                                          data.featured_product === 'yes' && <em className="text-primary icon ni ni-star-fill ms-1" title="Featured Product"></em>
                                        }

                                        {
                                          data.show_on_storefront === 'yes' && <em className="text-muted icon ni ni-browser ms-1" title="Showing on Storefront"></em>
                                        }

                                        <span className="d-md-none">{productStatusDot}</span>
                                      </span>

                                      {/* {data.category && <div className="price"><span className="badge bg-outline-gray rounded-pill">{data.category}</span></div>} */}
                                      {data.SKU && <div className="price d-none d-md-block"><small className="text-muted">SKU: {data.SKU}</small></div>}
                                      <div className="price d-md-none"><small>{currency} </small> {data.selling_price} • Qty: {data.quantity}</div>
                                    </div>
                                  </div>
                                </Link>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount">
                                  <small>{currency} </small> {data.cost_price}
                                </span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span className="tb-amount">
                                  <small>{currency} </small> {data.selling_price}
                                </span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                <span>{data.quantity}</span>
                              </DataTableRow>
                              <DataTableRow size="md">
                                {productStatus}
                              </DataTableRow>
                              <DataTableRow size="xl">
                                <span>{data.updated_at && moment(data.updated_at).format("Do MMM YYYY")}</span>
                              </DataTableRow>
                              <DataTableRow className="nk-tb-col-tools text-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                  >
                                    <Icon name="more-h"></Icon>
                                  </DropdownToggle>
                                  <DropdownMenu end>
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}/details/${data.product_id}`}>                                            
                                          <Icon name="eye"></Icon>
                                          <span>View Details</span>
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to={`${process.env.PUBLIC_URL}/products/b/${businessID}/edit/${data.parent_product_id}`}>
                                          <Icon name="edit-alt"></Icon>
                                          <span>Edit</span>
                                        </Link>
                                      </li>
                                      <li className="divider"></li>
                                      <li>
                                        <DropdownItem tag="a" className="pointer-cursor text-danger" onClick={() => handleSingleDelete(data.parent_product_id)}>
                                          <Icon name="trash"></Icon>
                                          <span>Delete</span>
                                        </DropdownItem>
                                      </li>
                                    </ul>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </DataTableRow>
                            </DataTableItem>
                          )
                        })
                      }
                    </DataTableBody>   
                    <PaginationWithOnclick currentPage={filters.page} pageCount={totalPages} loadNextPage={loadNextPage} />                                       
                  </>
                }              
              </div>
            </div>
          </Card>
        </>
      }
    </Block>
  );
};
